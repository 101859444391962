import React, { ReactNode } from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    TextField,
    Switch,
    Button,
    Select,
    MenuItem,
    Theme,
    FormControl,
    FormHelperText,
    Snackbar,
    styled
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import DeductionsController, {
    Props, configJSON, APIResponse, MyTypeAlias
} from "./DeductionsController.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import DonationsCharitableOrganization from "./DonationsCharitableOrganization.web";
import { DeleteIcon } from "./assets"
import AddIcon from '@material-ui/icons/Add';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export class Deductions extends DeductionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDynamicContent = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.dynamicDeductions.map((item: {
                    section_type: string,
                    name: string,
                    id: number | string,
                    value: string

                }, index: number) => {
                    return (
                        <Box className={classes.textgap} key={index}>
                            <Box className={classes.textAlignment} >
                                <Box data-test-id={"data" + item.section_type} >
                                    {this.renderSelectInput(classes.selectdropDownMaximum, item.section_type, item.section_type, (event: { target: { value: string; }; }) => this.handleOnchangeForDynamicSelectedValues(event, item.id, true, false), false, this.state.dropdownlist?.SectionDropdown)}

                                </Box>
                                <Box >
                                    <CloseIcon data-test-id={"closeicon" + index} onClick={this.deleteDynamicContent.bind(this, item.id)} />
                                </Box>
                            </Box>
                            <Box className={classes.textAlignmentfordynamicselect}>
                                {this.renderSelectInput(classes.selectdropDown, item.name, item.name, (event: { target: { value: string; }; }) => this.handleOnchangeForDynamicSelectedValues(event, item.id, false, true), false, configJSON.disiblity)}
                                {this.renderCostumTextfiled(item.section_type, item.value, (event: { target: { value: string; }; }) => this.handleOnchangeForDynamicSelectedValues(event, item.id, false, false), false, "")}
                            </Box>
                        </Box>
                    )
                })}
            </>)

    }
    renderSelectInput = (stylesprop: string,
        name: string,
        value: MyTypeAlias,
        onChange: ((event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>, child: React.ReactNode) => void) | any,
        error: boolean | undefined,
        apiResponse: APIResponse | string[]) => {
        let { classes } = this.props
        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="select"
                        value={value}
                        error={error}
                        name={name}
                        onChange={onChange}
                        onFocus={this.handleOnFocus.bind(this, name)}
                        data-test-id={name}
                        IconComponent={ExpandMoreIcon}
                    >
                        <MenuItem className={classes.menuStyles} value="choose"> <span className={classes.selectColor}>Select</span></MenuItem>
                        {apiResponse && Object.entries(apiResponse).map(([keys, value]) => (
                            <MenuItem key={keys} value={value}>
                                {value as ReactNode}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText className={classes.formMargin}>{error && configJSON.requriedfield}</FormHelperText>

                </FormControl>
            </Box>
        )
    }
    rendercostumButton = (name: string, stylesprop: string, onClick: React.MouseEventHandler<HTMLButtonElement>) => {
        return (
            <Box>
                <Button className={stylesprop} data-test-id={name} onClick={onClick}><AddIcon fontSize="medium" />{name}</Button>
            </Box>
        )

    }
    renderSwitch = (switchheading: string, switchSUbheading: string, checked: boolean, onchangeSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void, name: string,) => {
        const { classes } = this.props;
        return (
            <Box className={classes.switchWrapper}>
                <Box className={classes.textAlignmentSwitch}>
                    <Typography className={classes.subHeadingstyles}>{switchheading} </Typography>
                    <Typography className={classes.subHeadingstylesinfo}>{switchSUbheading} </Typography>
                </Box>
                <Box>
                    <CustomSwitch
                        checked={checked}
                        onChange={onchangeSwitch}
                        size="small"
                        name={name}
                        inputProps={{ "aria-label": "custom checkbox" }}
                        data-test-id={"switch" + name}
                    />
                </Box>
            </Box>
        )
    }
    renderCostumTextfiled = (name: string,
        value: MyTypeAlias,
        onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
        error: boolean | undefined,
        errorMessage: string
    ) => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.amount}>
                    <Box className={classes.ruppeslogo}>
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    </Box>
                    <TextField
                        className={classes.textfiled}
                        variant="outlined"
                        placeholder={configJSON.passplaceHolder}
                        name={name}
                        error={error}
                        type="text"
                        value={value}
                        onChange={onChange}
                        helperText={error && errorMessage}
                        onFocus={this.handleOnFocus.bind(this, name)}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                        inputProps={{ maxLength: 12}}
                        data-test-id={"data" + name}
                    />
                </Box>
            </>
        )
    }
    renderDonationListItems = (responseData: []) => {
        const { classes } = this.props;
        return (
            <>
                {responseData.length > 0 && responseData?.map((item: {
                    id: number;
                    donation_amount_cash: number;
                }, index: number) => (

                    <Box className={classes.listItem} key={item.id}>
                        <Typography className={classes.ruppeslogoColor}>Donation{index + 1}</Typography>
                        <Box className={classes.subListItem}>
                            <span className={classes.ruppeslogoColorDisply}>
                                <span>₹</span>
                                {item.donation_amount_cash}</span>
                            <img src={DeleteIcon} alt="delete" className={classes.imagewrpperDelete} data-test-id={"handleDelete" + item.id} onClick={this.donationsDelete.bind(this, item.id)} />
                        </Box>
                    </Box>
                ))}
            </>

        )
    }

    renderparentSpouseinputbase = (healthDetails: {
        namefirst: string,
        valuefirst: MyTypeAlias, onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
        errorParent: boolean | undefined,
        errorMessageParent: string,
        name: string,

    }) => {
        const { classes } = this.props;
        return (
            <Box>
                <span className={classes.inputlableStyle}>{healthDetails.name}</span>
                {this.renderCostumTextfiled(healthDetails.namefirst, healthDetails.valuefirst, healthDetails.onChange, healthDetails.errorParent, healthDetails.errorMessageParent)}

            </Box>
        )
    }
    renderCommenStyles = (defaultvalues: {
        mainheading: string, subheading: string, amount: string, textfieldDescription: string,
        maxAmountAny: string,
    },
        inputProperties: {
            name: string, value: MyTypeAlias, onchange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined, error: boolean, errormessage: string, apiResponse: {};
        },
        switchValues: {
            active: boolean, heading: string, subHeading: string, isActive: boolean, onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
            activeParent: boolean, headingParent: string, subHeadingParent: string, isActiveParent: boolean, onChangeParent?: (event: React.ChangeEvent<HTMLInputElement>) => void
        },
        buttonName: string,
        buttonType: string,
        selectparentsage: boolean,
        isListItems: boolean,
        isListSelected: boolean

    ) => {
        const { classes } = this.props;
        const { mainheading, subheading, amount, textfieldDescription, maxAmountAny, } = defaultvalues
        let renderElement;

        if (selectparentsage) {
            renderElement = this.renderSelectInput(classes.selectdropDown, inputProperties.name, inputProperties.value, inputProperties.onchange, inputProperties.error, inputProperties.apiResponse);
        } else if (buttonName === "") {
            renderElement = this.renderCostumTextfiled(inputProperties.name, inputProperties.value, inputProperties.onchange, inputProperties.error, inputProperties.errormessage);
        } else {
            renderElement = this.rendercostumButton(buttonName, classes.addnewDOnationBUtton, this.handleopendeductions.bind(this, buttonType));
        }

        return (
            <Box className={classes.textgap}>
                <Box className={classes.textAlignment}>
                    <Box className={classes.headingwrapper}>
                        <Typography ><span className={classes.subHeadingstyles}> {subheading}</span><span className={classes.secondHeadingstyles}>{mainheading}</span> </Typography>
                    </Box>
                    {amount && <Box className={classes.maxAmount}>
                        <Typography className={classes.amountstyles}>{amount}</Typography>
                    </Box>}
                </Box>
                <Box className={classes.textAlignment}>
                    <Box className={classes.boxMainWrapper}>
                        <Typography className={classes.txtfileddesc}>{textfieldDescription}</Typography>
                        {maxAmountAny && <Typography className={classes.txtfileddesc}>{maxAmountAny}</Typography>}
                    </Box>
                    {renderElement}
                </Box>
                {isListItems && !isListSelected && buttonType === 'section80g' && this.renderDonationListItems(this.state.section80g)}
                {isListItems && !isListSelected && buttonType === 'section80gga' && this.renderDonationListItems(this.state.section80gga)}

                {isListSelected && !isListItems && this.renderDonationListItems(this.state.section80ggc)}


                {switchValues.active && this.renderSwitch(switchValues.heading, switchValues.subHeading, this.state.TaxableIncome.HealthInsuranceforSelfSpouse, this.handleCheckedValues, "HealthInsuranceforSelfSpouse")}

                {this.state.TaxableIncome.HealthInsuranceforSelfSpouse && switchValues.active
                    &&
                    <Box className={classes.switchWrapper}>
                        <Box style={{ marginBottom: '15px' }}>
                            <Typography className={classes.txtfileddescMargin} >
                                {configJSON.healthcheckupselfspouce}
                            </Typography>
                        </Box>
                        <Box className={classes.wrapperswitch}>
                            {this.renderparentSpouseinputbase({
                                namefirst: "HealthInsuranceforSelfSpouseFamily",
                                valuefirst: this.state.TaxableIncome.HealthInsuranceforSelfSpouseFamily,
                                onChange: this.handleonChangSnFDeductions,
                                errorParent: this.state.errors["HealthInsuranceforSelfSpouseFamily"],
                                errorMessageParent: "required field",
                                name: "Self and family"
                            })}
                            {this.renderparentSpouseinputbase({
                                namefirst: "HealthInsuranceforSelfSpouseParents",
                                valuefirst: this.state.TaxableIncome.HealthInsuranceforSelfSpouseParents,
                                onChange: this.handleonChangSnFDeductions,
                                errorParent: this.state.errors["HealthInsuranceforSelfSpouseParents"],
                                errorMessageParent: "required field",
                                name: "Parents"
                            })}
                        </Box>
                    </Box>

                }

                {switchValues.activeParent && this.renderSwitch(switchValues.headingParent, switchValues.subHeadingParent, this.state.TaxableIncome.Healthinsuranceforparents, this.handleCheckedValues, "Healthinsuranceforparents",)}
                {this.state.TaxableIncome.Healthinsuranceforparents && switchValues.active
                    &&
                    <Box className={classes.switchWrapperchild}>
                        <Box className={classes.parentwrapper}>
                            <Box className={classes.boxWrapperWidth}>
                                <Typography className={classes.txtfileddesc} >
                                    {configJSON.healthcheckupselfparentOne}
                                </Typography>
                            </Box>
                            <Box>
                                <Box className={classes.wrapperswitch}>
                                    {this.renderparentSpouseinputbase({
                                        namefirst: "HealthinsuranceforparentsFamilyStepOne",
                                        valuefirst: this.state.TaxableIncome.HealthinsuranceforparentsFamilyStepOne,
                                        onChange: this.handleonChangeTextDataForDeductions,
                                        errorParent: this.state.errors["HealthinsuranceforparentsFamilyStepOne"],
                                        errorMessageParent: "required field",
                                        name: "Self and family"
                                    })}
                                    {this.renderparentSpouseinputbase({
                                        namefirst: "HealthinsuranceforparentsParentsStepOne",
                                        valuefirst: this.state.TaxableIncome.HealthinsuranceforparentsParentsStepOne,
                                        onChange: this.handleonChangeTextDataForDeductions,
                                        errorParent: this.state.errors["HealthinsuranceforparentsParentsStepOne"],
                                        errorMessageParent: "requried filed",
                                        name: "Parents"
                                    })}
                                </Box>
                            </Box>
                        </Box>
                        {this.state.TaxableIncome.Section80D !== "60 or above" &&
                            <Box className={classes.parentwrapper}>
                                <Box className={classes.boxWrapperWidth}>
                                    <Typography className={classes.txtfileddesc} >
                                        {configJSON.healthcheckupselfparenttwo}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className={classes.wrapperswitch}>
                                        {this.renderparentSpouseinputbase({
                                            namefirst: "HealthinsuranceforparentsFamilyStepTwo",
                                            valuefirst: "",
                                            onChange: this.handleonChangeTextDataForDeductions,
                                            errorParent: this.state.errors["HealthinsuranceforparentsFamilyStepTwo"],
                                            errorMessageParent: "required field",
                                            name: "Self and family"
                                        })}
                                        {this.renderparentSpouseinputbase({
                                            namefirst: "HealthinsuranceforparentsParentsStepTwo",
                                            valuefirst: "",
                                            onChange: this.handleonChangeTextDataForDeductions,
                                            errorParent: this.state.errors["HealthinsuranceforparentsParentsStepTwo"],
                                            errorMessageParent: "requried filed",
                                            name: "Parents"
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        }

                    </Box>
                }
            </Box>







        )
    }
    renderPageForDeductions = () => {
        const { Section80C,
            Section80CCD,
            Section80D,
            Section80TTASection80TTB
        } = this.state.TaxableIncome;
        const { classes } = this.props;
        return (
            <CustomeContainer className={classes.mainWrapper} maxWidth={false}>
                <Box className={classes.initialwrapper}>
                    <Typography className={classes.mainheadingstyles}>{configJSON.deductionHeading}</Typography>
                </Box>
                <Box className={classes.backgroundWrapper}>
                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.section80C,
                            subheading: "Section80C - ",
                            amount: configJSON.section80CMaxamount,
                            textfieldDescription: configJSON.section80CDescription,
                            maxAmountAny: configJSON.emptyString,
                        },
                        { name: "Section80C", value: Section80C, onchange: this.handleonChangeTextDataForDeductions, error: this.state.errors["Section80C"], errormessage: "Check your Max Limit", apiResponse: "" },
                        {
                            active: false, heading: "", subHeading: "", isActive: false,
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false
                        },
                        configJSON.emptyString,
                        configJSON.emptyString,
                        false,
                        false,
                        false


                    )
                    }
                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.Section80CCD,
                            subheading: "Section80CCD(1B) -",
                            amount: "",
                            textfieldDescription: configJSON.Section80CCDDescription,
                            maxAmountAny: configJSON.Section80CCDMaxAmont,
                        },

                        { name: "Section80CCD", value: Section80CCD, onchange: this.handleonChangeTextDataForDeductions, error: this.state.errors["Section80CCD"], errormessage: "Check your Max Limit", apiResponse: "" },
                        {
                            active: false, heading: "", subHeading: "", isActive: false,
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false,
                        },
                        configJSON.emptyString,
                        configJSON.emptyString,
                        false,
                        false,
                        false
                    )

                    }
                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.section80D,
                            subheading: "Section80D - ",
                            amount: "",
                            textfieldDescription: configJSON.section80DDescription,
                            maxAmountAny: "",
                        },

                        { name: "Section80D", value: Section80D, onchange: this.handleonChangeTextDataForDeductions, error: this.state.errors["Section80D"], errormessage: "Check your Max Limit", apiResponse: this.state.dropdownlist?.ParentAgeGroup },
                        {
                            active: true,
                            heading: configJSON.section80DSwitchHeadingSelf,
                            subHeading: configJSON.section80DSwitchsubHeadingSelf,
                            isActive: true,
                            activeParent: true,
                            headingParent: configJSON.section80DSwitchHeadingParent,
                            subHeadingParent: configJSON.section80DSwitchsubHeadingParent,
                            isActiveParent: false,
                        },
                        configJSON.emptyString,
                        configJSON.emptyString,
                        true,
                        false,
                        false
                    )

                    }
                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.Section80G,
                            subheading: "Section 80G - ",
                            amount: "",
                            textfieldDescription: configJSON.Section80GDescription,
                            maxAmountAny: "",
                        },

                        { name: configJSON.Section80G, value: "", onchange: undefined, error: false, errormessage: "", apiResponse: "" },
                        {
                            active: false, heading: "", subHeading: "", isActive: false,
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false,
                        },
                        configJSON.addnewDOnationBUtton,
                        "section80g",
                        false,
                        true,
                        false

                    )
                    }
                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.Section80TTASection80TTB,
                            subheading: "Section80TTA / Section 80TTB -  ",
                            amount: "",
                            textfieldDescription: configJSON.Section80TTASection80TTBDescription,
                            maxAmountAny: "",
                        },
                        { name: "Section80TTASection80TTB", value: Section80TTASection80TTB, onchange: this.handleonChangeTextDataForDeductions, error: this.state.errors["Section80TTASection80TTB"], errormessage: "Check your Max Limit", apiResponse: "" },
                        {
                            active: false, heading: "", subHeading: "", isActive: false,
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false,
                        },
                        configJSON.emptyString,
                        configJSON.emptyString,
                        false,
                        false,
                        false

                    )
                    }

                    {this.renderCommenStyles(
                        {
                            mainheading: configJSON.Section80GGCSubHeading,
                            subheading: "Section80GGC - ",
                            amount: "",
                            textfieldDescription: configJSON.Section80GGCSubHeading,
                            maxAmountAny: "",
                        },

                        { name: configJSON.Section80G, value: "", onchange: undefined, error: false, errormessage: "", apiResponse: "" },
                        {
                            active: false, heading: "", subHeading: "", isActive: false,
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false,
                        },
                        "Add Entry",
                        "section80ggc",
                        false,
                        false,
                        true

                    )
                    }
                    {this.renderCommenStyles(
                        {
                            subheading: "Section80GGA - ",
                            mainheading: configJSON.Section80GGCSubHeading,
                            textfieldDescription: configJSON.Section80GGCSubHeading,
                            maxAmountAny: "",
                            amount: "",

                        },

                        { name: configJSON.Section80G, value: "", onchange: undefined, error: false, errormessage: "", apiResponse: "" },
                        {
                            activeParent: false, headingParent: "", subHeadingParent: "", isActiveParent: false,
                            active: false, heading: "", subHeading: "", isActive: false,
                        },
                        "Add Entry ",
                        "section80gga",
                        false,
                        true,
                        false

                    )
                    }

                    {this.renderDynamicContent()}
                    <Box className={classes.buttonalignment}>
                        {this.rendercostumButton(configJSON.addOthers, classes.addOtherBUtton, this.handleAddDynamicDeductions)}
                        <Button className={classes.addOtherBUtton} onClick={this.handleSubmit} data-test-id="submit-btm">{configJSON.submitData}</Button>
                    </Box>
                </Box>
            </CustomeContainer>
        )
    }
    renderLoader = () => {
        const { classes } = this.props;

        if (this.state.isLoaded) {
            return (
                <Box className={classes.loaderContainer}>
                    <CircularProgress size="4em" />
                </Box>
            );
        } else {
            return this.renderPageForDeductions()
        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {!this.state.opendeductions ?
                    <>
                        <DashboardPanselector navigation={this.props.navigation} id={""} />
                        <Snackbar anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }} open={this.state.openStatus} autoHideDuration={5000} data-test-id="snack-bar" onClose={this.handleTextMessage}>
                            <Alert severity="success">
                                {configJSON.succesmessageSnackBar}
                            </Alert>
                        </Snackbar>

                        {this.renderLoader()}
                    </> : <DonationsCharitableOrganization navigation={undefined} id={this.state.id} dropdown={this.state.dropdownlist} type={this.state.pageType} onClick={this.handleopendeductions.bind(this, this.state.pageType)} />
                }

            </>
            // Customizable Area End
        );
    }
}


// Customizable Area Start
const styles = (theme: Theme) => ({
    ruppeslogoColorDisply: {
        color: "#858786",
        display: "flex",
        flexDirection: "row" as const,
        gap:'10px'
    },
    ruppeslogoColor: {
        color: "#858786"
    },
    boxWrapperWidth: {
        width: "50%",
        [theme.breakpoints.between('xs', 'sm')]: {
            width: "100%"
        },
    },
    boxMainWrapper: {
        width: "60%",
        "@media(max-width: 767px)": {
        width: '100%'
        }
    },
    formMargin: {
        margin: '-12px 0 0 0'
    },
    selectColor: {
        color: "#ABABB0"
    },
    menuStyles: {
        display: "none", color: '#ABABB0'
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        background: "#F4F6F5",
        padding: "10px 20px",
        borderRadius: '16px',
        "@media(max-width: 767px)": {
            flexDirection: 'column'
        }
    },
    imagewrpperDelete: {
        cursor: "pointer"
    },
    subListItem: {
        display: "flex",
        gap: '40px',
        alignItems: "center"
    },
    initialwrapper: {
        padding: "2em 0em 0 0em"
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    textAlignmentfordynamicselect: {
        display: "flex",
        gap: '30px',
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            gap: '15px',
            flexDirection: "column"
        },
    },
    parentwrapper: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginBottom: '20px'
        }
    },
    buttonalignment: {
        display: "flex",
        gap: "20px",
        "@media(max-Width: 767px)": {
            flexDirection: 'column'
        }
    },
    wrapperswitch: {
        display: "flex", gap: "15px", [theme.breakpoints.down('xs')]: {
            display: "unset"
        },
    },
    inputlableStyle: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginBottom: '1em'
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    subHeadingstylesinfo: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
    },
    txtfileddesc: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",

    },
    txtfileddescMargin: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginBottom: '-12px'
    },
    secondHeadingstyles: {
        color: "#36403b",
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Nunito",
    },
    subHeadingstyles: {
        color: "#36403b",
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    mainheadingstyles: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
        "@media(max-width: 767px)": {
            fontSize: '20px'
        }
    },
    amountstyles: {
        color: "#399683",
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Nunito",

    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "1em 0 0 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    textAlignment: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            gap: '15px',
            flexDirection: "column"
        },
    },
    maxAmount: {
        borderRadius: "12px",
        background: "#ddfef7",
        padding: '5px 18px 5px 18px'
    },
    textgap: {
        display: "flex",
        gap: "25px",
        flexDirection: "column" as const,
        borderRadius: "14.5px",
        padding: '1.1em 1.3em',
        border: "0.5px solid #979797",
        margin: '2em',
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '2em',
        },
        "@media(max-width: 767px)": { 
            margin: '15px 0'
        }
    },
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 1.7em 1em 1em',
        height: '41px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    amount: {
        display: "flex",

    },
    textfiled: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    headingwrapper: {
        borderRadius: "8px",
        border: "0.5px solid #c3cad9",
        background: "#ffffff",
        padding: '0.5em 0.7em'
    },
    switchWrapper: {
        borderRadius: "16px",
        background: "#f4f6f5",
        padding: '1em 3em 1em 3em',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
        "@media(max-width: 767px)": {
            padding: '10px'
        }
    },
    switchWrapperchild: {
        borderRadius: "16px",
        background: "#f4f6f5",
        padding: '1em 3em 1em 3em',
        display: "flex",
        flexDirection: "column" as const,
        gap: '20px',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
            padding: '10px'
        },
    },
    textAlignmentSwitch: {
        display: "flex",
        flexDirection: 'column' as const,
        gap: '6px'
    },
    addnewDOnationBUtton: {

        borderRadius: "8px",
        color: " #c7aa61",
        background: "#fff5dc",
        padding: '0.5em 1em',
        fontWeight: 600,
        fontFamily: "Nunito",
        boxSizing: 'border-box' as const,
        '&:hover': {
            background: "#fff5dc",
        },
        "& .MuiButton-label": {
            display: "flex",
            gap: '10px',
            alignItems: "center"
        }

    },
    addOtherBUtton: {
        borderRadius: "8px",
        color: " #ffff",
        background: "#337152",
        padding: '0.5em 2em',
        margin: "2em 0em 0 2em",
        '&:hover': {
            background: " #337152"
        },
        "& .MuiButton-label": {
            display: "flex",
            gap: '10px',
            alignItems: "center"
        },
        "@media(max-width: 767px)": {
           margin: '0 15px'
        }
    },
    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    },
    selectdropDownMaximum: {
        maxWidth: '100%',
        width: "750px",
        "@media(max-width: 767px)": {
            width: '150px'
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    }

})

const CustomSwitch = withStyles({
    root: {
        width: "50px",
        height: "28px",
        padding: "2px 11px 6px 0px"
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: "ffffff",
            '& + $track': {
                backgroundColor: '#347054',
                opacity: 1,
                border: 'none'
            },
        },
        '&$focusVisible $thumb': {
            color: '#347054',
            border: '6px solid #fff'
        },
    },
    thumb: {
        width: 25,
        height: 25,
    },
    track: {
        borderRadius: 26 / 2,
        border: "1px solid grey",
        backgroundColor: "grey",
        opacity: 1
    },
    checked: {},
    focusVisible: {}
})(Switch);

const CustomeContainer = styled(Container)({

})
export default withStyles(styles)(Deductions);
// Customizable Area End