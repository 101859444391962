// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any,
  id: { id: string; type: string },
  // Customizable Area Start
  activeStep: any,
  steps: any,
  accordianSteps: any,
  classes: any,
  step: number
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  totalValue: number;
  emp_company: string;
  nature_of_employer: string;
  tan_of_employer: string;
  salary_as_per_section_17_1: any,
  basic_salary: any,
  house_rent_allowance: any,
  leave_travel_allowance: any,
  hra_exemption: any,
  lta_exemption: any,
  result: any,
  activeStep: number;
  form_step: number;
  salarydetails: any;
  pin_code: any;
  state: string;
  form_status: string;
  address_of_employer: string;
  city: string;
  status: string;
  formData: any;
  user_id: number;
  professional_tax_under_16_iii: string;
  relief_under_section_89_4b: string;
  income_claimed_for_relief_under_section_89_a: string;
  income_chargeable_under_head_salaries: string;
  method: string;
  errors: {
    emp_company: string;
    nature_of_employer: string;
    tan_of_employer: string;
    basic_salary: string,
    house_rent_allowance: string,
    leave_travel_allowance: string,
    hra_exemption: string,
    lta_exemption: string,
    salary_section17a: any,
    professional_tax_under_16_iii:string,
    relief_under_section_89_4b:string,
    income_claimed_for_relief_under_section_89_a:string,
    pin_code: string;
    city: string;
    state: string;
    address_of_employer: string;
  };
  activeItems: any;
  updateForm: boolean;
  activeStepAccordian: number | null;
  salary_section17a: any,
  prequisite_section17bs: any,
  salary_in_lieu_under_section_17_3: any,
  exampt_allowance_section10as: any,
  isSaveToDraft: any,
  natureOfEmployerOptions: string[],
  employerNature: string[],
  perquisites: string[],
  lieu_salary: string[],
  state_list: string[],
  exampt_allowances: string[],
  salaryPartDropdown: string[],
  snackbarOpen: boolean,
  gross_salary: number,
  perquisites_under_section_17_2: string,
  exempt_allowances_under_section_10: number,
  balance: string,
  standard_deductions_16_ia: number,
  oversea_retire_account: string
  salary17a: any,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  steps: any;
  accordianSteps: any;
  title: string;
  content: string;
  toggleStep: any;
  // Customizable Area End
}

export default class SalaryDetailsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tdsDetailsFormID: string = "";
  tdsDetailsFormUpdatesID: string = "";
  GetSalaryViewCallId: string = "";
  employerDetailsGet: string = "";
  natureEmployerId: string = "";
  perquisitesId: string = "";
  lieu_salaryId: string = "";
  salary_sectionId: string = "";
  exemptListId:string="";
  stateListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      totalValue:0,
      form_status: "",
      form_step: 0,
      method: "",
      isSaveToDraft: false,
      formData: {},
      emp_company: "",
      nature_of_employer: "",
      oversea_retire_account: "",
      tan_of_employer: "",
      state: "",
      address_of_employer: "",
      city: "",
      salary_as_per_section_17_1: 0,
      basic_salary: null,
      user_id: 0,
      house_rent_allowance:"",
      leave_travel_allowance: "",
      hra_exemption:"",
      lta_exemption: "",
      result: 0,
      gross_salary: 0,
      pin_code: "",
      salarydetails: "",
      professional_tax_under_16_iii: "",
      relief_under_section_89_4b: "",
      income_claimed_for_relief_under_section_89_a: "",
      income_chargeable_under_head_salaries:"",
      errors: {
        emp_company: "",
        nature_of_employer: "",
        tan_of_employer: "",
        basic_salary: "",
        house_rent_allowance: "",
        leave_travel_allowance: "",
        hra_exemption: "",
        lta_exemption: "",
        relief_under_section_89_4b:"",
        income_claimed_for_relief_under_section_89_a:"",
        professional_tax_under_16_iii: "",
        salary_section17a: [],
        pin_code: "",
        city: "",
        state: "",
        address_of_employer: ""
      },
      updateForm: false,
      status: "draft",
      activeItems: {},
      activeStepAccordian: 0,
      natureOfEmployerOptions: [],
      employerNature: [],
      perquisites: [],
      lieu_salary: [],
      state_list: [],
      salaryPartDropdown: [],
      exampt_allowances: [],
      salary17a: {SalarySection17a: {section_name:'', amount:""},
      PrequisiteSection17b: {section_name:'', amount:""},
      ExamptAllowanceSection10a: {section_name:'', amount:""},
      ProfitsInLieuUnderSection17c:{section_name:'', amount:""}},

      salary_section17a: [{ section_name: '', amount: ""}],
      prequisite_section17bs: [{ section_name: '', amount: "" }],
      salary_in_lieu_under_section_17_3: [{ section_name: '', amount: "" }],
      exampt_allowance_section10as: [{ section_name: '', amount: "" }],
      snackbarOpen: false,
      perquisites_under_section_17_2: "",
      exempt_allowances_under_section_10: 0,
      balance: "",
      standard_deductions_16_ia: 50000
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
      if (errorResponse || !apiRequestCallId || !responseJson) {
        return;
      }

      const apiResponse = responseJson;
      if (apiResponse && apiResponse.data.attributes.form_status==="submitted") {
        window.location.reload()
      }
    };

    if (this.tdsDetailsFormID === apiRequestCallId || this.tdsDetailsFormUpdatesID === apiRequestCallId) {
      handleApiResponse(apiRequestCallId, responseJson);
      return;
    }

    if (this.GetSalaryViewCallId === apiRequestCallId || this.employerDetailsGet === apiRequestCallId) {
        this.handleSalaryViewResponse(responseJson, errorResponse);
    }

    this.employerNatureDropdown(apiRequestCallId, responseJson);
    this.perquisitesDropdown(apiRequestCallId, responseJson);
    this.lieu_salaryDropdown(apiRequestCallId, responseJson);
    this.state_listDropdown(apiRequestCallId, responseJson);
    this.salarySectionDropdown(apiRequestCallId, responseJson);
    this.exemptListDropdown(apiRequestCallId, responseJson);
  }  

  employerNatureDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.natureEmployerId === apiRequestCallId){
      this.setState({
        employerNature: responseJson.employment_and_tds_details
      })
    }
  }

  perquisitesDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.perquisitesId === apiRequestCallId){
      this.setState({
        perquisites: responseJson.perquisites
      })
    }
  }

  lieu_salaryDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.lieu_salaryId === apiRequestCallId){
      this.setState({
        lieu_salary: responseJson.profits_in_lieu_of_salary
      })
    }
  } 
  
  salarySectionDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.salary_sectionId === apiRequestCallId){
      this.setState({
        salaryPartDropdown: responseJson.gross_breakdown
      })
    }
  }
  
  exemptListDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.exemptListId === apiRequestCallId){
      this.setState({
        exampt_allowances: responseJson.exampt_allowances
      })
    }
  }  

  state_listDropdown = (apiRequestCallId: any, responseJson: any) => {
    if(this.stateListId === apiRequestCallId){
      this.setState({
        state_list: responseJson.emp_state
      })
    }
  }  

  handleSalaryViewResponse(responseJson: any, errorResponse: any) {
    if (!responseJson || !responseJson.data) {
      this.setState({
        method:  "POST" 
      });

      return;
    }

    this.setState({
      method: responseJson.data.length === 0 ? "POST" : "PATCH"
    });
    const data = responseJson.data && responseJson?.data?.attributes;
    this.setState({ user_id: responseJson?.data && responseJson?.data.id});

    /* istanbul ignore next */
    if (data) {
      const {
        emp_company ,
        nature_of_employer,
        oversea_retire_account,
        tan_of_employer,
        basic_salary,
        house_rent_allowance,
        leave_travel_allowance,
        hra_exemption,
        lta_exemption,
        professional_tax_under_16_iii,
        relief_under_section_89_4b,
        income_claimed_for_relief_under_section_89_a,
        income_chargeable_under_head_salaries,
        exempt_allowances_under_section_10,
        standard_deductions_16_ia= 50000,
        pin_code,
        city,
        gross_salary,
        status,
        state,
        address_of_employer,
        balance,
        salary_section17a= [{ section_name: '', amount:""}],
        prequisite_section17bs= [{ section_name: '', amount:"" }],
        salary_in_lieu_under_section_17_3= [{ section_name: '', amount:"" }],
        exampt_allowance_section10as= [{ section_name: '', amount:"" }],
      } = data;

      this.setState({
        emp_company,
        nature_of_employer,
        tan_of_employer,
        basic_salary,
        house_rent_allowance,
        leave_travel_allowance,
        hra_exemption,
        lta_exemption,
        professional_tax_under_16_iii,
        relief_under_section_89_4b,
        income_claimed_for_relief_under_section_89_a,
        income_chargeable_under_head_salaries,
        exempt_allowances_under_section_10,
        pin_code,
        city,
        gross_salary,
        status,
        state,
        address_of_employer,
        balance,
        oversea_retire_account,
        salary_section17a,
        prequisite_section17bs,
        salary_in_lieu_under_section_17_3,
        exampt_allowance_section10as,
        standard_deductions_16_ia
      });
      this.setState({
        activeStep: this.props.step
      })
    }
  }

  async componentDidMount() {
    // Customizable Area Start
    if (this.props.id.type === "employer" || this.props.id.type === "salary_structure") {
      this.employerDetailsById();
    } else {
      this.callGetFormData();
    }
    this.lieuSalaryDataCall();
    this.stateListDataCall();
    this.employerNatureData();
    this.perquisitesDataCall();
    this.salarySectionDataCall();
    this.exemptListDataCall();
    // Customizable Area End
  }
  
  async componentDidUpdate(){
      this.calculatePartA()
  }


  toggleSteps = (stepIndex: number) => {
    this.setState((prevState) => ({
      activeStepAccordian: prevState.activeStepAccordian === stepIndex ? null : stepIndex,
    }))
  }

  handleAddSalaryPartA = () => {
    this.setState((prevState) => ({
      salary_section17a: [...prevState.salary_section17a, {
        section_name: '', amount: ""
      }]
    }));
  }

  handleAddSalaryPartB = () => {
    this.setState((prevState) => ({
      prequisite_section17bs: [...prevState.prequisite_section17bs, {
        section_name: '', amount: ""
      }]
    }));
  }

  handleAddsalary_in_lieu_under_section_17_3 = () => {
    this.setState((prevState) => ({
      salary_in_lieu_under_section_17_3: [...prevState.salary_in_lieu_under_section_17_3, {
        section_name: '', amount: ""
      }]
    }));
  }

  exemption = () => {
    this.setState((prevState) => ({
      exampt_allowance_section10as: [...prevState.exampt_allowance_section10as, {
        section_name: '', amount: ""
      }]
    }));
  }

  handleDeleteSalaryPartA = (index: any) => {
    this.setState((prevState) => {
      const deleteSalaryPartA = [...prevState.salary_section17a];
      const latestTotal = prevState.gross_salary - parseFloat(deleteSalaryPartA[index].amount);
      deleteSalaryPartA.splice(index, 1);
      return { salary_section17a: deleteSalaryPartA, gross_salary: latestTotal};
    });
  }

  handleDeleteSalaryPartB = (index: any) => {
    this.setState((prevState) => {
      const deleteSalaryPartB = [...prevState.prequisite_section17bs];
      const latestTotal = prevState.gross_salary - parseFloat(deleteSalaryPartB[index].amount);
      deleteSalaryPartB.splice(index, 1);
      return { prequisite_section17bs: deleteSalaryPartB, gross_salary: latestTotal};
    });
  }

  handleDeletesalary_in_lieu_under_section_17_3 = (index: any) => {
    this.setState((prevState) => {
      const deletesalary_in_lieu_under_section_17_3 = [...prevState.salary_in_lieu_under_section_17_3];
      const latestTotal = prevState.gross_salary - parseFloat(deletesalary_in_lieu_under_section_17_3[index].amount);
      deletesalary_in_lieu_under_section_17_3.splice(index, 1);
      return { salary_in_lieu_under_section_17_3: deletesalary_in_lieu_under_section_17_3, gross_salary: latestTotal };
    });
  }

  handleDeleteExemption = (index: any) => {
    this.setState((prevState) => {
      const deleteexemption = [...prevState.exampt_allowance_section10as];
      const latestTotal:any = Number(prevState.exempt_allowances_under_section_10) - Number(deleteexemption[index].amount);
      deleteexemption.splice(index, 1);
      return { exampt_allowance_section10as: deleteexemption , exempt_allowances_under_section_10: latestTotal };
    });
  }

  handleSelectChangeSalaryPartA = (index: any, e: any) => {
    const updatedSalaryPartA = [...this.state.salary_section17a];
    updatedSalaryPartA[index].section_name = e.target.value;
    this.setState({
      salary_section17a: updatedSalaryPartA
    });
  }

  handleSelectChangeSalaryPartB = (index: any, e: any) => {
    const updatedSalaryPartB = [...this.state.prequisite_section17bs];
    updatedSalaryPartB[index].section_name = e.target.value;
    this.setState({
      prequisite_section17bs: updatedSalaryPartB
    });
  }

  handleSelectChangesalary_in_lieu_under_section_17_3 = (index: any, e: any) => {
    const updatedsalary_in_lieu_under_section_17_3 = [...this.state.salary_in_lieu_under_section_17_3];
    updatedsalary_in_lieu_under_section_17_3[index].section_name = e.target.value;
    this.setState({
      salary_in_lieu_under_section_17_3: updatedsalary_in_lieu_under_section_17_3
    });
  }

  handleSelectChangeExempt = (index: any, e: any) => {
    const updatedChangeExempt = [...this.state.exampt_allowance_section10as];
    updatedChangeExempt[index].section_name = e.target.value;
    this.setState({
      exampt_allowance_section10as: updatedChangeExempt
    });
  }

  handleNextStep = () => {
    this.setState({ isSaveToDraft: false, activeStep: this.state.activeStep + 1 });
  };

  handlePrevStep = () => {
    this.setState({ activeStep: this.state.activeStep - 1, });
  };

  employerNatureData() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.natureEmployerId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.natureemployer}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callGetFormData() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSalaryViewCallId = getValidationsMsg.messageId;


    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfocritr17/draft_status"
      
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  perquisitesDataCall() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.perquisitesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.prerequesites}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  lieuSalaryDataCall() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.lieu_salaryId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.lieuSalary}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }



  salarySectionDataCall() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.salary_sectionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EmployerListApiCall}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  exemptListDataCall(){
    const headers = {
      "token" : localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.exemptListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.exemptList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  stateListDataCall() {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken),
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stateList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  createTDSFormData = (state: any, status: any) => {
    const SalarySection17a = {}
    state.salary_section17a.map((ele: any) => {
      Object.assign(SalarySection17a, {
        [ele.section_name]: ele.amount
      });
    });
    
    const PrequisiteSection17b = {}
    state.prequisite_section17bs.map((ele: any) => {
      Object.assign(PrequisiteSection17b, {
        [ele.section_name]: ele.amount
      });
    });
    const ExamptAllowanceSection10a = {}
    state.exampt_allowance_section10as.map((ele: any) => {
      Object.assign(ExamptAllowanceSection10a, {
        [ele.section_name]: ele.amount
      });
    });
    const ProfitsInLieuUnderSection17c = {}
    state.salary_in_lieu_under_section_17_3.map((ele: any) => {
      Object.assign(ProfitsInLieuUnderSection17c, {
        [ele.section_name]: ele.amount
      });
    });
    const slaraySectionData = {
      SalarySection17a,
      PrequisiteSection17b,
      ExamptAllowanceSection10a,
      ProfitsInLieuUnderSection17c
    }

    return {
      emp_company: state.emp_company,
      nature_of_employer: state.nature_of_employer,
      tan_of_employer: state.tan_of_employer,
      oversea_retire_account: state.oversea_retire_account,
      basic_salary: state.basic_salary,
      house_rent_allowance: state.house_rent_allowance,
      leave_travel_allowance: state.leave_travel_allowance,
      hra_exemption: state.hra_exemption,
      lta_exemption: state.lta_exemption,
      professional_tax_under_16_iii: state.professional_tax_under_16_iii,
      relief_under_section_89_4b: state.relief_under_section_89_4b,
      income_claimed_for_relief_under_section_89_a: state.income_claimed_for_relief_under_section_89_a,
      exempt_allowances_under_section_10: state.exempt_allowances_under_section_10,
      pin_code: state.pin_code,
      city: state.city,
      gross_salary: state.gross_salary,
      form_status: status,
      state: state.state,
      address_of_employer: state.address_of_employer,
      standard_deductions_16_ia: state.standard_deductions_16_ia,
      balance: Math.abs(state.exempt_allowances_under_section_10 - state.gross_salary),
      income_chargeable_under_head_salaries: Math.abs(state.balance - state.income_claimed_for_relief_under_section_89_a - state.standard_deductions_16_ia),
      salary_as_per_section_17_1: this.calculatePartA(),
      salary17a: JSON.stringify(slaraySectionData),
    };
  };

  tdsFormDetailsAPI = (status: string) => {
    const stepbar = status === "submitted" ? "submitted" : "draft";
    const header = {
      "token": localStorage.getItem(configJSON.authToken)
    };

    const formData = new FormData();
    const tdsData = this.createTDSFormData(this.state, status);

    Object.entries(tdsData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tdsDetailsFormID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TDSDetailsApiCall}?status=${stepbar}`
      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  tdsFormDetailsUpdatesAPI = (status: string,apiEndpOint:string) => {
    const stepbar = status === "submitted" ? "submitted" : "draft";
    const header = {
      "token": localStorage.getItem(configJSON.authToken)
    };

    const formData = new FormData();
    const tdsData = this.createTDSFormData(this.state, status);

    Object.entries(tdsData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tdsDetailsFormUpdatesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndpOint}/${this.state.user_id}?status=${stepbar}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.examplePatchAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  tdsFormDetailsValidation = () => {
    let isFormValid = true;
    const tanRegex = /^[A-Z]{4}\d{5}[A-Z]$/;
    const employerNameRegex = /^[A-Za-z\s]+$/;
  
    if (this.state.emp_company && this.state.emp_company.trim() === "") {
      this.state.errors['emp_company'] = "Employer name cannot be just blank spaces";
      isFormValid = false;
    }
    if (this.state.emp_company && !employerNameRegex.test(this.state.emp_company)) {
      this.state.errors['emp_company'] = "Please enter correct name";
      isFormValid = false;
    }
  
    if (this.state.tan_of_employer && !tanRegex.test(this.state.tan_of_employer)) {
      this.state.errors['tan_of_employer'] = "Please enter correct employer TAN";
      isFormValid = false;
    }
  
    return isFormValid;
  }
  

  salaryFormDetailsValidation = () => {
    let isFormValid = true;
    const bsRegex = /^\d{1,8}$/;
    if (this.state.basic_salary === ""||this.state.basic_salary === null) {
      this.state.errors['basic_salary'] = "Please enter amount"
      isFormValid = false
    }
    else if (!bsRegex.test(this.state.basic_salary)) {
      this.state.errors['basic_salary'] = "Please enter correct amount"
      isFormValid = false
    }

    if (this.state.house_rent_allowance === ""|| this.state.house_rent_allowance === null) {
      this.state.errors['house_rent_allowance'] = "Please enter amount"
      isFormValid = false
    }
    else if (!bsRegex.test(this.state.house_rent_allowance)) {
      this.state.errors['house_rent_allowance'] = "Please enter correct amount"
      isFormValid = false
    }

    if (this.state.leave_travel_allowance === ""|| this.state.leave_travel_allowance === null) {
      this.state.errors['leave_travel_allowance'] = "Please enter amount"
      isFormValid = false
    }
    else if (!bsRegex.test(this.state.leave_travel_allowance)) {
      this.state.errors['leave_travel_allowance'] = "Please enter correct amount"
      isFormValid = false
    }

    if (this.state.hra_exemption === ""|| this.state.hra_exemption === null) {
      this.state.errors['hra_exemption'] = "Please enter amount"
      isFormValid = false
    }
 
    if (this.state.lta_exemption === ""||this.state.lta_exemption === null) {
      this.state.errors['lta_exemption'] = "Please enter amount"
      isFormValid = false
    }

    
    return isFormValid
  }

  /* istanbul ignore next */
  employerAddressValidation = () => {
    let isFormValid = true;
    let pincode = /^\d{6}$/;
    if (this.state.pin_code === "") {
      this.state.errors['pin_code'] = "Please enter pin code"
      isFormValid = false
    }
    else if (!pincode.test(this.state.pin_code)) {
      this.state.errors['pin_code'] = "Please Enter correct pin code"
      isFormValid = false
    }

    if (this.state.city === "" || !this.state.city) {
      this.state.errors['city'] = "Please enter city name"
      isFormValid = false
    }

    if (this.state.state === "") {
      this.state.errors['state'] = "Please Select state"
      isFormValid = false
    }

    if (this.state.address_of_employer === "") {
      this.state.errors['address_of_employer'] = "Please Enter address"
      isFormValid = false
    }
    return isFormValid
  } 

  handleOnChangeSalary = (event:{target:{name:string,value:string}}) => {
    const { value,name } = event.target;
    this.setState((prevState) => ({
        ...prevState,
        [name]:value,
    }),() => this.calculateTotal());
  }

  handleInputChangeSalaryPartA = (index: any, e: any) => {
    const updatedSalaryPartA = [...this.state.salary_section17a];
    updatedSalaryPartA[index].amount = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      salary_section17a: updatedSalaryPartA
    }),() => this.calculateTotal());
  }

  handleInputChangeSalaryPartB = (index: any, e: any) => {
    const updatedSalaryPartB = [...this.state.prequisite_section17bs];
    updatedSalaryPartB[index].amount = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      prequisite_section17bs: updatedSalaryPartB
    }),() => this.calculateTotal());
  }

  handleInputChangesalary_in_lieu_under_section_17_3 = (index: any, e: any) => {
    const updatedsalary_in_lieu_under_section_17_3 = [...this.state.salary_in_lieu_under_section_17_3];
    updatedsalary_in_lieu_under_section_17_3[index].amount = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      salary_in_lieu_under_section_17_3: updatedsalary_in_lieu_under_section_17_3
    }),() => this.calculateTotal());
  }

  handleOnChangeExemption = (event:{target:{name:string,value:string}}) => {
    const { value,name } = event.target;
    this.setState((prevState) => ({
        ...prevState,
        [name]: value,
    }),() => this.exempt_allowances_under_section_10());
  }

  handleInputChangeExemption = (index: any, e: any) => {
    const updatedExemption = [...this.state.exampt_allowance_section10as];
    updatedExemption[index].amount = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      exampt_allowance_section10as: updatedExemption
    }),() => this.exempt_allowances_under_section_10());
  }

  calculatePartA = () => {
    const { salary_section17a, house_rent_allowance=0, basic_salary=0, leave_travel_allowance=0 } = this.state;
  
    let salarypartA = Number(house_rent_allowance) + Number(basic_salary) + Number(leave_travel_allowance);
  
    salary_section17a.forEach((salarySectionA: any) => {
      salarypartA += Number(salarySectionA.amount)||0;
    });
  
    return Number(salarypartA).toString();
  };
  

  calculateTotal = () => {
    const {
      salary_in_lieu_under_section_17_3,
      salary_section17a,
      prequisite_section17bs,
      house_rent_allowance=0,
      basic_salary=0,
      leave_travel_allowance=0
    } = this.state;
  
    let gross_salary = Number(house_rent_allowance)
                     + Number(basic_salary)  
                     + Number(leave_travel_allowance);
  
    salary_section17a.forEach((salarySectionA: any) => {
      gross_salary += parseFloat(salarySectionA.amount) || 0;
    });
  
    prequisite_section17bs.forEach((salarySectionB: any) => {
      gross_salary += parseFloat(salarySectionB.amount) || 0;
    });
  
    salary_in_lieu_under_section_17_3.forEach((salarySectionC: any) => {
      gross_salary += parseFloat(salarySectionC.amount) || 0;
    });
  
    this.setState({ gross_salary });
  };
  
  

  exempt_allowances_under_section_10 = () => {
    const { exampt_allowance_section10as, lta_exemption, hra_exemption } = this.state;
    let exempt_allowances_under_section_10 = Number(lta_exemption) + Number(hra_exemption);
    exampt_allowance_section10as.forEach((exemption: any) => {
      exempt_allowances_under_section_10 += Number(exemption.amount)
    });
    this.setState({ exempt_allowances_under_section_10 });
  };

  handleOnChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }


  displayError = (errorKey: keyof S['errors']) => {
    const { errors } = this.state;
    if (errors[errorKey]) {
      return (
          errors[errorKey]
      );
    }
    return null;
  };

  handleFocus = (event: { target: { name: string; }; }) => {
    const { name } = event.target;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  }

  tdsDetailsSubmit = () => {
    if (this.tdsFormDetailsValidation()) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.clickaway) {
      return;
    }
    this.setState({ snackbarOpen: false })
  };

  savetheDraftTDS = (status: string) => {
    if (this.tdsFormDetailsValidation()) {
      if (this.state.method == "POST") {
        this.tdsFormDetailsAPI(status)
      } 
      else if (this.props.id.type === "salary_structure"){
        this.tdsFormDetailsUpdatesAPI(status,configJSON.employerEndpoint)
      } else{
        this.tdsFormDetailsUpdatesAPI(status, configJSON.TDSDetailsApiCall)
      }
      this.setState({snackbarOpen:true})
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  salaryDetailsDrafts = (status: string) => {
    if (this.salaryFormDetailsValidation()) {
      if (this.state.method == "POST") {
        this.tdsFormDetailsAPI(status)
      } else if (this.props.id.type === "salary_structure"){
        this.tdsFormDetailsUpdatesAPI(status,configJSON.employerEndpoint)
      } else{
        this.tdsFormDetailsUpdatesAPI(status, configJSON.TDSDetailsApiCall)
      }
      this.setState({snackbarOpen:true})
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  EmployerAddressDetailsDrafts = (status: string) => {
    if (this.employerAddressValidation()) {
      if (this.state.method == "POST") {
        this.tdsFormDetailsAPI(status)
      } else if (this.props.id.type === "salary_structure"){
        this.tdsFormDetailsUpdatesAPI(status,configJSON.employerEndpoint)
      }else{
        this.tdsFormDetailsUpdatesAPI(status, configJSON.TDSDetailsApiCall)
      }
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  salaryDetailsSubmit = (status: string) => {
    if (this.salaryFormDetailsValidation()) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  windowReload = () => {
    window.location.reload()
  }

  employerDetailsSubmit = (status: string) => {
    if (this.employerAddressValidation()) {
      if (this.state.method == "POST") {
        this.tdsFormDetailsAPI(status)
      }
       if (this.props.id.type==="salary_structure"){
        this.tdsFormDetailsUpdatesAPI(status,configJSON.employerEndpoint)
      }else{
        this.tdsFormDetailsUpdatesAPI(status, configJSON.TDSDetailsApiCall)
      }
      this.setState({snackbarOpen:true})
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }
  }

  employerDetailsById = async () => {
    const {id} = this.props
    const salaryID = id?.type === "salary_structure" ? "salary_structures": "employers";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employerDetailsGet = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employerEndpointDashboard }/${salaryID}/${id?.id}`
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
// Customizable Area End
