import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
export const companyCodeData = require("./codes.json");
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    changeTab?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    nameofBusiness: string;
    commonBusiness: string;
    grossRecipt: any;
    netProfit: any;
    presumptiveIncome: any;
    error: any;
    codes: any,
    filteredCodes: any,
    searchValue: any,
    selectedValue: any,
    grossReceiptscheques:any,
    grossReceiptscash:any,
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BusinessProfessionalIncomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    businessFormId:string="";
    incomeFormId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            nameofBusiness: "",
            commonBusiness: "choose",
            grossRecipt: "",
            netProfit: "",
            presumptiveIncome: "",
            error: {},
            codes: [],
            filteredCodes: [],
            searchValue: "",
            selectedValue: "",
            grossReceiptscheques:"",
            grossReceiptscash:"",
            toast: {
                type: "success",
                open: false,
                message: "",
              },
        
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.setState({ codes: companyCodeData });
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (this.businessFormId === apiCallId) {
            if(responseJson.errors){
                this.setState({
                    toast: { open: true, message: responseJson.errors, type: "error" },
                });
            }
            else{
                this.setState({
                    toast: { open: true, message: "Professional Income Updated successfully", type: "success" },
                });
            }
        }
        if (this.incomeFormId === apiCallId) {
            if(responseJson.errors){
                this.setState({
                    toast: { open: true, message: responseJson.errors, type: "error" },
                });
            }
            else{
                this.setState({
                    toast: { open: true, message: "Professional Income Updated successfully", type: "success" },
                });
            }
        }
    }
    handleOnchangeBusiness = (event: any) => {
        const { name, value } = event.target;
        const validNumber = /^\d+(\.\d+)?$/;
    
        if ((name == "grossRecipt" || name == "netProfit") && (!validNumber.test(value) && value !== '')) {
            return;
        }
    
        this.setState((prevData) => {
            const newState = {
                ...prevData,
                [name]: value,
            };
    
            const grossRecipt50 = newState.grossRecipt ? parseFloat(newState.grossRecipt) * 0.5 : 0;
            const netProfit50 = newState.netProfit ? parseFloat(newState.netProfit) : 0;
    
            newState.presumptiveIncome = grossRecipt50 === 0 && netProfit50 === 0 ? 0 : Math.max(grossRecipt50, netProfit50);
    
            return newState;
        });
    };
    

    handleOnchangeIncome = (event: any) => {
        const { name, value } = event.target;
        const validNumber = /^\d+(\.\d+)?$/;
        if ((name == "grossRecipt" || name == "netProfit" || name == "grossReceiptscash" || name == "grossReceiptscheques") &&
            !validNumber.test(value) && value !== '') {
            return;
        }
    
        this.setState((prevData) => {
            const newState = {
                ...prevData,
                [name]: value,
            };
    
            const grossRecipt50 = parseFloat(newState.grossRecipt || 0);
            const netProfit50 = parseFloat(newState.netProfit || 0);
            const grossReceiptscash = parseFloat(newState.grossReceiptscash || 0);
            const grossReceiptscheques = parseFloat(newState.grossReceiptscheques || 0);
            const totalgross = (grossRecipt50 + grossReceiptscash + grossReceiptscheques) * 0.5;
            newState.presumptiveIncome = (grossRecipt50 === 0 && netProfit50 === 0 && grossReceiptscash === 0 && grossReceiptscheques === 0)
                ? 0
                : Math.max(netProfit50, totalgross);
    
            return newState;
        });
    };
    
    
    
    
    
    handleFocusBusiness = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurBusiness = (event: { target: { name: string, value: string } }) => {
        const { name,value } = event.target;
     this.setState((prevData) => ({
                error: {
                    ...prevData.error,
                    [name]: String(value).trim() === "" 
                }
            }));
    }
    handleSubmitBusinessData=()=>{
        const header = {
          "token": localStorage.getItem(configJSON.authToken),
          'Content-Type': 'application/json'
        };
        
        const data = {
            "name_of_business": this.state.nameofBusiness,
            "common_80g_organisation":this.state.selectedValue,
            "gross_reciepts": Number(this.state.grossRecipt),
            "net_profit": Number(this.state.netProfit),
            "presumptive_income": Number(this.state.presumptiveIncome)
          }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.businessFormId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.businessFormSubmmit}`
          
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleSubmitIncomeData=()=>{
        const header = {
          "token": localStorage.getItem(configJSON.authToken),
          'Content-Type': 'application/json'
        };
        
        const data = {
            "name_of_business": this.state.nameofBusiness,
            "common_80g_organisation":this.state.selectedValue,
            "gross_reciepts": Number(this.state.grossRecipt),
            "net_profit":  Number(this.state.netProfit),
            "presumptive_income":  Number(this.state.presumptiveIncome),
            "gross_reciepts_through_cheque":  Number(this.state.grossReceiptscheques),
            "gross_reciepts_through_cash":  Number(this.state.grossReceiptscash),
          }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.incomeFormId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.businessFormSubmmit}`
          
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleSearch = (event:any) => {
        const { codes } = this.state;
        const searchValue = event.target.value;
    
        const filteredCodes = codes.filter((company:any) => company.code.startsWith(searchValue));
    
        this.setState({ searchValue, filteredCodes });
      };
    
    handleChange = (event:any,newValue: any) => {
        this.setState({ selectedValue: newValue ? newValue.code : '' });
    };
    handlenavigation = ()=> {
        this.props.changeTab("Business Income")
    }
    handleTostClose = () => {
        this.setState({
          toast: {
              open: false,
              message: this.state.toast.message,
              type: this.state.toast.type,
          },
          });
     
  }
    // Customizable Area End
}
