// Customizable Area Start
import React from 'react';
import DatePicker from "react-multi-date-picker";
import {
  // Customizable Area Start
  Box,
  Typography,
  withStyles,
  Grid,
  Input,
  Checkbox,
  TextField,
  Snackbar,
  styled
  // Customizable Area End
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RentalPropertyEditController, { Props, configJSON } from './RentalPropertyEditController.web';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { calanderIcon, deleteRed, LeftArrow } from './assets';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export class RentalPropertyEdit extends RentalPropertyEditController {

  constructor(props: Props) {
    super(props);
  }

  renderTextFiled = (inputprops: {
    name: string,
    value: string,
    type: string,
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    error: boolean | undefined,
    message: string,
  }
  ) => {
    const { classes } = this.props;
    const {
      name,
      value,
      type,
      onChange,
      error,
      message,
    } = inputprops
    return (
      <Box className={classes.stringwrapper}>
        <TextField
          className={classes.textfiledWrapperString}
          error={error}
          variant="outlined"
          fullWidth
          placeholder="Enter"
          name={name}
          type="text"
          value={value}
          onChange={onChange}
          onFocus={this.handleFocusRentalProperty}
          onBlur={this.handleBlurRentalProperty}
          data-test-id={name}
          helperText={error && configJSON.requriedfield}
          FormHelperTextProps={{
            style: { margin: '0' },
          }}
        />

        <Box className={classes.topmarginText}>
          {type == "true" &&

            <Typography variant="caption" className={classes.underhovertext} >
              <Box className={classes.iconAlign}>
                <HelpOutlineIcon />{message}
              </Box>
            </Typography>
          }
        </Box>
      </Box>
    )
  }

  renderCostumTextfiledforRentalProperty = (inputpropsRental: {
    name: string,
    value: string,
    type: string,
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    error: boolean | undefined,
    message: string,
  }
  ) => {
    const {
      error,
      message,
      name,
      value,
      type,
      onChange,
    } = inputpropsRental
    const { classes } = this.props;
    return (
      <>
        <Box className={classes.amountWrapper}>
          <Box className={classes.ruppeslogoWrapper}>
            <Typography className={classes.rupeessstyles}>₹</Typography>
          </Box>
          <TextField
            name={name}
            fullWidth
            type="number"
            variant="outlined"
            placeholder={configJSON.passplaceHolder}
            className={classes.textfiledWrapper}
            value={value}
            onChange={onChange}
            onFocus={this.handleFocusRentalProperty}
            onBlur={this.handleBlurRentalProperty}
            data-test-id={name}
            error={error}
            helperText={error }
            FormHelperTextProps={{
              style: { margin: '0' },
            }}
          />
        </Box>
        <Box className={classes.topmargin}>
          {type == "true" &&
            <Typography variant="caption" className={classes.underhovertext} >
              <Box className={classes.iconAlign}>
                <HelpOutlineIcon />{message}
              </Box>
            </Typography>
          }
        </Box>
      </>
    )
  }




  steps = [
    <div className='stepNo'> <span>1</span>Income Details</div>,
    <div className='stepNo'> <span>2</span>{configJSON.interestPaidDuringPreConstrn}</div>,
    <div className='stepNo'> <span>3</span>Tenant Details</div>,
    <div className='stepNo'> <span>4</span>{configJSON.coOwnersDetails}</div>

  ];

  second() {
    const { classes } = this.props;
    return (
      <div>
        <Box className={`${classes.mainWrapper} tdsEditForm`}>
          <Grid>
            <h2 className='mt0 mb10 formHeading' data-test-id="second-form"> {configJSON.interestPaidDuringPreConstrn} </h2>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <label className={classes.labelHeading} htmlFor="fname">
                  Financial year previous to completion of construction
                </label>
              </Grid>
              <Grid item sm={6}>
                <Box style={{ width: '80%' }}>
                  <CustomCalander style={{ position: "relative" }}>
                    <Box className="calander_icon">
                      <img src={calanderIcon} />
                    </Box>
                    <Box className="rmdp-input--1">
                    <DatePicker
                      editable={false}
                      placeholder="DD/MM/YYYY"
                      data-testid="date_of_birth"
                      format="DD/MM/YYYY"
                      weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                      name="date_of_birth"
                      value={this.state.rentFinYear}
                      onChange={(date) => {this.handleGetDateOfBirth(date)}}
                      required={true}
                      maxDate={new Date()}
                    />
                    </Box>
                  </CustomCalander>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <label className={classes.labelHeading} htmlFor="fname">
                  Total interest amount paid during pre-construction period
                </label>
              </Grid>
              <Grid item sm={6}>
                {this.renderCostumTextfiledforRentalProperty(
                  {
                    name: "rentInt",
                    value: this.state.rentInt,
                    type: "true",
                    onChange: this.handleOnchangeRentalProperty,
                    error: this.state.error["rentInt"],
                    message: configJSON.interestPaid,

                  }
                )
                }
              </Grid>

            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }

  first() {
    const { classes } = this.props;
    return (
      <div className={`accordianStep ${this.state.activeStep === 0 ? 'active' : ''}`}>
        <Box className={`${classes.mainWrapper} tdsEditForm`}>
          <Grid>
            <h2 className='mt0 mb10 formHeading' data-test-id="first-form" > {configJSON.IncomeDetailsText} </h2>
            <Grid container spacing={2} >
              <Grid item sm={6}>
                <label className={classes.labelHeading} htmlFor="fname" >
                  {configJSON.AnnualRentReceivedbyyou}
                </label>
              </Grid>
              <Grid item sm={6}>
                {this.renderCostumTextfiledforRentalProperty(
                  {
                    name: "firstRent",
                    value: this.state.firstRent,
                    type: "true",
                    onChange: this.handleOnchangeRentalProperty,
                    error: this.state.error["firstRent"],
                    message: configJSON.helperfirstRent

                  }
                )
                }
              </Grid>

            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <label className={classes.labelHeading} htmlFor="fname">
                  {configJSON.MunicipalfirstRentTax}
                </label>
              </Grid>
              <Grid item sm={6}>
                {this.renderCostumTextfiledforRentalProperty(
                  {
                    name: "firstRentTax",
                    value: this.state.firstRentTax,
                    type: "true",
                    onChange: this.handleOnchangeRentalProperty,
                    error: this.state.error["firstRentTax"],
                    message: configJSON.helperfirstRentTax,

                  }
                )
                }
              </Grid>

            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <label className={classes.labelHeading} htmlFor="fname">
                  {configJSON.firstRentIntInterest}
                </label>
              </Grid>
              <Grid item sm={6}>
                {this.renderCostumTextfiledforRentalProperty(
                  {
                    name: "firstRentInt",
                    value: this.state.firstRentInt,
                    type: "false",
                    onChange: this.handleOnchangeRentalProperty,
                    error: this.state.error["firstRentInt"],
                    message: "",

                  }
                )
                }
              </Grid>

            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }

  fourth() {
    const { classes } = this.props;
    return (
      <div>
        <Box className={`${classes.mainWrapper} tdsEditForm`}>
          <h2 data-test-id="fourth-form"> {configJSON.coOwnersDetails} </h2>
          <Typography className={classes.labelHeading}>In case this property is jointly owned, please specify co-owners</Typography>
          {this.state.coOwner.map((item:{
            id:string,
            name_of_coowner:string,
            pan_of_coowner:string,
            percentage_share:string,

          }, index: number) => (
            <div key={index}>
              <Grid container spacing={2} style={{ marginTop: 40 }}>
                <Grid sm={3} item>
                  <Typography className={classes.labelHeading}>Name of Co-owner</Typography>
                  <Input disableUnderline value={item.name_of_coowner} name='name_of_coowner' data-test-id={"coOwnerName" + index}
                    onChange={this.handleMultipleChangeDataRental.bind(this, item.id)}

                  />
                </Grid>
                <Grid sm={3} item>
                  <Typography className={classes.labelHeading}>PAN of Co-owner</Typography>
                  <Input disableUnderline value={item.pan_of_coowner} name='pan_of_coowner' data-test-id={"coOwnerPAN" + index}
                    onChange={this.handleMultipleChangeDataRental.bind(this, item.id)}
                  />
                </Grid>
                <Grid sm={3} item>
                  <Typography className={classes.labelHeading}>Percentage Share</Typography>
                  <Input disableUnderline value={item.percentage_share} name='percentage_share' data-test-id={"coOwnerPercShare" + index}
                    onChange={this.handleMultipleChangeDataRental.bind(this, item.id)}
                  />
                </Grid>
                <Grid sm={3} item style={{ display: 'flex', alignItems: 'flex-end' }} >
                  <img src={deleteRed} style={{ cursor: 'pointer' }} data-test-id={"rental" + index} onClick={this.handleDeleteMultipleItemsRental.bind(this, item.id)} />
                </Grid>
              </Grid>
            </div>
          ))}
          <div data-test-id='addField' style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '1rem 0 2rem 0' }} onClick={this.handleAddMultipleItemsRental}>
            <AddCircleIcon style={{ color: '#337152', margin: '0 5px 0 -10px' }} />
            <Typography className={classes.addMore}>Add More Items</Typography>
          </div>
          <p className={classes.errorpancolour}>{this.state.error["coOwner"] ? configJSON.errorPanmessage : null}</p>

        </Box>
      </div>
    )
  }

  third() {
    const { classes } = this.props;

    return (

      <div>
        <Box className={`${classes.mainWrapper} tdsEditForm`}>
          <h2>Tenant Details </h2>
          <Grid container spacing={2} style={{ marginTop: 40 }}>
            {this.state.tenent.map((item: {
              id: string | number,
              name_of_tenant: string,
              pan_of_tenant: string,
              percentage_share_of_tenant: number,
            }, index: number | string) => {
              return (
                <Box key={index} style={{ display: "flex" }}>
                  <Grid sm={3} item>
                    <Typography className={classes.labelHeading} data-test-id="third-form">Name of Tenant</Typography>
                    <Input disableUnderline value={item.name_of_tenant} name='name_of_tenant' data-test-id={"coOwnerName" + index}
                      onChange={this.handleMultipleChangeTenentDetail.bind(this, item.id)}
                    />
                  </Grid>
                  <Grid sm={3} item>
                    <Typography className={classes.labelHeading}>PAN of Tenant</Typography>
                    <Input disableUnderline value={item.pan_of_tenant} name='pan_of_tenant' data-test-id={"coOwnerPAN" + index}
                      onChange={this.handleMultipleChangeTenentDetail.bind(this, item.id)}
                    />
                  </Grid>
                  <Grid sm={3} item>
                    <Typography className={classes.labelHeading}>Percentage Share</Typography>
                    <Input disableUnderline value={item.percentage_share_of_tenant} name='percentage_share_of_tenant' data-test-id={"coOwnerPercShare" + index}
                      onChange={this.handleMultipleChangeTenentDetail.bind(this, item.id)}
                    />
                  </Grid>
                  <Grid sm={3} item style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <img src={deleteRed} style={{ cursor: 'pointer' }} data-test-id={"deleteProperty" + index} onClick={this.handleDeleteMultipleTenentDetail.bind(this, item.id)} />
                  </Grid>
                </Box>
              )

            })}


          </Grid>
          <div data-test-id='addField' style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '1rem 0 2rem 0' }} onClick={this.handleAddMultipleTenentDetail}>
            <AddCircleIcon style={{ color: '#337152', margin: '0 5px 0 -10px' }} />
            <Typography className={classes.addMore}>Add More Items</Typography>
          </div>
          <p className={classes.errorpancolour}>{this.state.error["tenent"] ? configJSON.errorPanmessage : null}</p>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
            <Checkbox />
            <Typography className={classes.labelHeading}>Same as the address in Personal Info</Typography>
          </div>

          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.flatNum}
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantFlatNum",
                  value: this.state.tenantFlatNum,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantFlatNum"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.premiseName}
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantPremiseName",
                  value: this.state.tenantPremiseName,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantPremiseName"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.roadName}
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantStreetName",
                  value: this.state.tenantStreetName,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantStreetName"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.pincode}
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantPincode",
                  value: this.state.tenantPincode,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantPincode"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>Area/ Locality
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantArea",
                  value: this.state.tenantArea,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantArea"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>Town/ City
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantCity",
                  value: this.state.tenantCity,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantCity"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.state}
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantState",
                  value: this.state.tenantState,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantState"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
          <br />
          <Box style={{ padding: 12, display: 'flex' }}>
            <Typography style={{ width: '40%' }} className={classes.labelHeading}>{configJSON.country}
              <span className="redSign">*</span>
            </Typography>
            <Box style={{ width: '45%' }}>
              {this.renderTextFiled(
                {
                  name: "tenantCountry",
                  value: this.state.tenantCountry,
                  type: "false",
                  onChange: this.handleOnchangeRentalProperty,
                  error: this.state.error["tenantCountry"],
                  message: "",
                }
              )
              }
            </Box>
          </Box>
        </Box>
      </div>
    )
  }

  renderLoaderRentalProperty = () => {
    const { classes } = this.props;

    if (this.state.isLoadingRentalProperty) {
      return (
        <Box className={classes.loaderContainer} data-test-id="loader">
          <CircularProgress size='4em' />
        </Box>
      );
    } else {
      return this.renderStepRental()

    }

  }
  renderStepRental = () => {
    const { classes } = this.props;
    const { activeStep } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box className='commonFormBg' sx={{ width: '100%' }}>
          <div>

            <div className="step-bar" data-test-id="initial-render">
              <Box className={classes.stepbar} data-test-id="handle-steps" onClick={this.handleStepsRental}>
                <img src={LeftArrow} />
              </Box>
              {this.steps.map((step1, index) => {
                index += 1
                return (<div
                  key={index}
                  className={`step ${index < activeStep ? 'visited' : ''} ${index === activeStep ? 'active' : ''}`}

                >
                  {step1}
                </div>)
              })}
            </div>
            <div className="step-content">
              {(() => {
                switch (activeStep) {
                  case 1:
                    return this.first()
                  case 2:
                    return this.second()
                  case 3:
                    return this.third()
                  case 4:
                    return this.fourth()
                }
              })()}
            </div>
          </div>

          <button className='commonBtn' data-test-id="nextBtn" >Save as Draft</button>
          <button className='commonBtn ml' onClick={this.handleValidationRentalSubmit} data-test-id="savethedraftBtn" >
            {this.state.activeStep >= 4 ? 'Submit' : 'Save and Next'}
          </button>

        </Box>
      </ThemeProvider>
    )
  }

  render() {
    return (
      <>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleSnackbarClose} >
          <Alert severity="success">
            {configJSON.datasucessMessage}
          </Alert>
        </Snackbar>
        {this.renderLoaderRentalProperty()}
      </>
    )
  }



}
const styles = {
  errorpancolour: {
    color: "red"
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  mainWrapper: {
    borderRadius: 16,
    background: '#FFF',
    padding: 10
  },
  stepbar: {
    display: "flex",
    marginTop: '1em',
    alignItems: "flex-start",
    cursor: "pointer"

  },
  commonFormBg:{
    "& input:focus": {
      border:"none"
      // borderRadius: "0px 8px 8px 0px",
    }
  },
  amount: {
    display: "flex",

  },

  labelHeading: {
    color: 'rgba(54, 64, 59, 0.70)',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600,
    fontFamily: 'Nunito',
  },
  queTypo: {
    fontSize: 16,
    width: '60%',
    display: 'flex',
    alignItems: 'flex-start',
    color: 'rgba(54, 64, 59, 0.50)',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    paddingTop: 10
  },

  ruppeslogo: {
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    background: "#ddfef7",
    padding: '1em 2em 1em 1em',
    height: '42px',
    alignItems: "center"
  },
  inputStyle: {
    flexDirection: "column" as const,
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    height: "100px",
    justifyContent: "space-between",
    display: "flex",
  },
  amountWrapper: {
    display: "flex",
    width: '60%',
    "& input": {
      border: "none"
    },
    "& input:focus": {
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: "0px 8px 8px 0px",
    }
  },
  ruppeslogoWrapper: {
    background: "#ddfef7",
    padding: '1em 2em 1em 1em',
    height: '42px',
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    alignItems: "center"
  },
  rupeessstyles: {
    color: "rgba(54,64,59,0.9)",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Nunito",
  },
  textfiledWrapper: {

    "& input": {
      padding: "11px 0px 11px 12px",

      border: "1px solid #grey"
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: '#c3cad9',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderLeft: 'none',
        borderRadius: "0px 8px 8px 0px",
      },
    },

  },
  topmargin: {
    marginTop: "3px",
    width: "63%",
    maxWidth: "100%"
  },
  topmarginText: {
    marginTop: "3px",
    maxWidth: "100%"
  },
  underhovertext: {
    color: "rgba(54,64,59,0.5)",
    fontSize: "12px",
    fontWeight: 3500,
    fontFamily: "Nunito",
  },
  iconAlign: {
    display: "flex",
    gap: "10px"
  },
  stringwrapper: {
    // maxWidth: '70%',
    "& input": {
      border: "none"
    },
    "& input:focus": {
      border: "none",
      borderRadius: "0px",
    }
  },
  textfiledWrapperString: {
    "& input": {
      padding: "11px 0px 11px 12px",

      border: "1px solid #grey"
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: '#c3cad9',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "8px",
      },
    },
  },
};

const CustomCalander = styled(Box)({
  "& .rmdp-day >span:hover": {
    backgroundColor: "#DEF5EA6B !important",
    color: "black !important"
  },
  "& .rmdp-day >span": {
    borderRadius: "20%",
  },
  "& .rmdp-day": {
    height: "40px",
    width: "30.5px !important"
  },
  "& .rmdp-today >span": {
    backgroundColor: "transparent",
    color: "black"
  },
  "& .rmdp-input--1": {
    border: "1px solid",
    borderColor: '#c6c6c6',
    maxWidth: "234px",
    borderRadius: "7px"
  },
  "& .rmdp-input": {
    backgroundColor: "transparent",
    position: "relative",
    zIndex: 1000,
    height: "48px",
    border: "none",
    paddingLeft: "70px",
    fontSize: "18px",
    width: "100%"
  },
  "& .rmdp-input:focus": {
    outline: "none",      // Removes the outline
    borderColor: "transparent",  // Removes any border color on focus
    boxShadow: "none"      // Removes any potential box shadow
  },
  "& .rmdp-input::placeholder": {
    color: "rgba(54, 64, 59, 0.50)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .calander_icon": {
    position: "absolute",
    backgroundColor: "#DEF5EA",
    height: "100%",
    width: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px 0px 0px 7px"
  },
  "& .rmdp-week-day": {
    backgroundColor: "#DEF5EA6B !important",
    color: "black !important"
  },
  "& .rmdp-arrow": {
    borderColor: "black !important"
  },
  "& .rmdp-header-values": {
    fontSize: "13.5px"
  }
});


export default withStyles(styles)(RentalPropertyEdit);

