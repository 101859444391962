import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core'

interface MyComponentProps {

    name: any;
    icon: string;
    Amount: string;
}

export class SalaryListCostum extends React.Component<MyComponentProps> {

    render() {

        const { classes }: any = this.props;
        const { name, icon } = this.props
        const arr = [1, 2, 3]
        const data = arr.length
        return (
            <Box className={classes.ullistbackground}>
                {Object.entries(name ?? {}).map(([key, value], index, arr) => {
                    const isLastIteration = index === arr.length - 1;
                    if (value == 0 || value == 0.0) return null;

                    return (
                        <>
                            <Box className={classes.backgroundpriceList} style={{ flexWrap: 'wrap' }} key={key}>
                                <Typography className={classes.deductions} style={{ wordBreak: 'break-all' }}>{key}</Typography>
                                <Box className={classes.pricelogo}>
                                    <img src={icon} className={classes.rupeelogo} />
                                    {value}
                                </Box>
                            </Box>
                            {!isLastIteration && <hr className={classes.hrcolor} />}
                        </>
                    );
                })}
            </Box>
        )
    }

}
const styles = {
    ullistbackground: {
        borderRadius: "16px 10px 10px 16px",
        background: "#F4F6F5",
        margin: '0.5em 0 0 1.7em',
    },
    backgroundpriceList: {
        padding: "1em 0 1em 0",
        margin: "0 4em 0 3em",
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width:675px)": {
            flexDirection: "column" as const,
            alignItems: "center"
        },
    },
    deductions: {
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
        fontWeight: 500
    },
    logo: {
        maxWidth: "30.27px",
        width: "100%",
        height: "auto",
    },
    rupeelogo: {
        maxWidth: '13px',
        width: "100%",
        height: 'auto'
    },
    pricelogo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        marginRight: '2em'
    },

    hrcolor: {
        strokeWidth: "0.5px",
        stroke: "#C5C5C5",
        margin: '0 3em 0 3em',
        color: "lightgrey"
    }
}

export default withStyles(styles)(SalaryListCostum);

// Customizable Area End