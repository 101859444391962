import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    Theme,
    // Customizable Area Start
    TextField,
    FormControl,
    Select,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End

import BusinessProfessionalIncomeController, {
    Props,
    configJSON,
} from "./BusinessProfessionalIncomeController.web";
import Toster from "../../../components/src/Toster";

export class BusinessProfessionalIncome extends BusinessProfessionalIncomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    
    IncomerendercommonComponetSelectInputDonations = (stylesprop: string, name: string, value: string, onChange:any, error: boolean | undefined) => {
        const { classes } = this.props;

        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="select"
                        error={error}
                        name={name}
                        value={value}
                        data-test-id={name}
                        onChange={onChange}
                        onFocus={this.handleFocusBusiness}
                    >
                        <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>

                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> 
                    </Select>

                    <Box>
                        {<Typography variant="caption" className={classes.helpertext}>
                            {configJSON.helpertext}
                        </Typography>}
                    </Box>

                </FormControl >
            </Box>

        )
    }
    IncomerenderStandardInputforBusinessIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <TextField
                className={classes.selectdropDown}
                variant="outlined"
                error={error}
                disabled={name == "presumptiveIncome"}
                placeholder={configJSON.emptyPlaceholder}
                name={name}
                type="text"
                value={value}
                onChange={onChange}
                onFocus={this.handleFocusBusiness}
                data-test-id={name}
                helperText={error && "requried field"}
                FormHelperTextProps={{
                    style: { margin: '-12px 0 0 0' },
                }}

            />
        )
    }
    IncomerenderCostumTextfiledforBusinessIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <Box className={classes.amountWrapper}>
                <Box className={classes.ruppeslogoWrapper}>
                    <Typography className={classes.rupeessstyles}>₹</Typography>
                </Box>
                <TextField
                disabled={name == "presumptiveIncome"}
                    className={classes.textfiledWrapper}
                    error={error}
                    variant="outlined"
                    placeholder={configJSON.passplaceHolder}
                    name={name}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleFocusBusiness}
                    data-test-id={name}
                    helperText={error && "requried field"}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}

                />
            </Box>
        )
    }

    IncomerenderCommonTextFieldforBusinessIncome = (commonAttributes:
        {
            heading: string,
            amountActive: boolean,
            selectactive:boolean,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
        }) => {
        const { classes } = this.props;
        const { amountValues, textInputValues } = commonAttributes
        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.IncomerenderCostumTextfiledforBusinessIncome(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        }else if (commonAttributes.selectactive){
            rendertxt = this.IncomerendercommonComponetSelectInputDonations(classes.selectdropDown ,amountValues.name, amountValues.value, amountValues.onChange, amountValues.error)

        }
        else {
            rendertxt = this.IncomerenderStandardInputforBusinessIncome(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)
        }

        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box className={classes.boxwidth}>
                        <Typography className={classes.labelheading} >{commonAttributes.heading}</Typography>
                    </Box>
                    <Box>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }
    IncomerendercommonComponetDeductionsforBusinessIncome = () => {
        const { classes } = this.props;
        const { nameofBusiness,
            commonBusiness,
            grossRecipt,
            netProfit,
            presumptiveIncome, error } = this.state;
            const { selectedValue } = this.state;

        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.natureofBussiness}</Typography>
                </Box>
                {this.IncomerenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.nameofBusiness,
                    amountActive: false,
                    selectactive:false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "nameofBusiness", value: nameofBusiness, type: "string", onChange: this.handleOnchangeBusiness, error: error["nameofBusiness"] },
                })}
                 <Box>
                    <Box className={classes.textAlignment} style={{alignItems:"flex-start"}}>
                        <Box className={classes.boxwidth}>
                            <Typography className={classes.labelheading} >{"Business code"}</Typography>
                        </Box>
                        <Box className={classes.stylesprop}>
                            <FormControl variant="outlined" error={error} style={{ width: '500px' }}>
                                <Autocomplete
                                    options={this.state.codes}
                                    getOptionLabel={(option: any) => option.code}
                                    value={this.state.codes.find((item: any) => item.code === selectedValue) || null}
                                    onChange={this.handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search or select business code"
                                            variant="outlined"
                                            error={false}
                                            className={classes.selectDropDown}
                                            InputProps={{
                                                ...params.InputProps,
                                                classes: {
                                                  notchedOutline: classes.customBorder, 
                                                  input: classes.Autocompleteinput
                                                },
                                              }}
                                        />
                                    )}
                                />
                                
                            </FormControl>
                        </Box>
                    </Box>
            </Box>

            <Box className={classes.headingwrapeer}  style={{paddingTop:"30px"}}>
                    <Typography className={classes.subheadingdonations}>{configJSON.receiptsandprofit}</Typography>
                </Box>
           
               
                {this.IncomerenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.grossReceipts,
                    amountActive: true,
                    selectactive:false,
                    amountValues: { name: "grossRecipt", value: grossRecipt, type: "number", onChange: this.handleOnchangeBusiness, error: error["grossRecipt"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.IncomerenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.netProfits,
                    amountActive: true,
                    selectactive:false,
                    amountValues: { name: "netProfit", value: netProfit, type: "number", onChange: this.handleOnchangeBusiness, error: error["netProfit"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.IncomerenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.PresumptiveIncome,
                    amountActive: true,
                    selectactive: false,
                    amountValues: {
                        name: "presumptiveIncome",
                        value: presumptiveIncome,
                        type: "number",
                        onChange: undefined,
                        error: error["presumptiveIncome"]
                    },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}


            </>
        )
    }
    rendercommonComponetProfessionalIncome = () => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.DonationDetails}</Typography>
                </Box>
            </>
        )
    }
    
    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
             <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
                <Container className={classes.mainWrapper} maxWidth={false}>

                    <Box className={classes.conditionalheading}>
                        <span style={{cursor:"pointer"}} data-test-id="back_button" onClick={()=>this.handlenavigation()}><ArrowBackIosIcon fontSize="large" /></span><Typography className={classes.donationdeading}>Professional Income (44ADA)</Typography>
                    </Box>
                    <Box className={classes.backgroundWrapper}>
                        {this.IncomerendercommonComponetDeductionsforBusinessIncome()}
                    </Box>
                    <Box >
                        <Button className={classes.buttonComponent} onClick={this.handleSubmitBusinessData} data-test-id="next" >Submit</Button>
                    </Box>
                </Container>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

export const styles = (theme: Theme) => ({
    Autocompleteinput: {
        height: '10px', 
        padding: '8px 10px',
        boxSizing: 'border-box',
      } as const,
    amountWrapper: {
        display: "flex"
    },
    citystate: {
        display: "flex",
        width: "100%",
        gap: '20px',
        maxWidth: "500",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    ruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        display: "flex",
        borderRadius: "8px 0px 0px 8px",
        alignItems: "center"
    },

    textfiledWrapper: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    textAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 8em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },

    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    },

    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    boxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },
    customBorder: {
        border: '1px solid #C4C2C2', 
      },
    underhovertext: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },


    subheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },


    labelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },

    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },

    inputlableStyle: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
    },
    helpertext: {
        display: "flex",
        aliginItems: "center",
        gap: "5px",
        marginTop: '0.9em',
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 350,
        fontFamily: "Nunito",

    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },

    headingwrapeer: {
        marginLeft: '2em'

    },

})
export default withStyles(styles)(BusinessProfessionalIncome);
// Customizable Area End