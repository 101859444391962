import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";


export interface APIResponse {
  [key: string]: string;
}
export type MyTypeAlias = string | null | number;


interface Deduction {
  data:{
    id:string
    attributes:{
      section80c:string,
      section80ccd:string,
      section80tta_or_section80ttb:string,
      Section80d:{
        data:{
          attributes:{
            parent_age_group:string,
            spouse_details:{
              data:{
                attributes:{
                  spouce_insurance:boolean,
                  self_and_family:string,
                  parent:string
                }
              }
            },
            parent_details:{
              data:[{
                attributes:{
                  parent_insurance:boolean,
                  self_and_family:string,
                  parent:string
                },
              },
              {
                attributes:{
                  self_and_family:string,
                  parent:string
                }
              }
            ]
            }
          }
        }
      },
      Sections:[]
    }
  }
}

interface ErrorObject {
  [key: string]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dynamicDeductions: { section_type: string, name: string, value: string, id: string | number;[key: string]: string | number }[],
  dropdownlist: any,
  opendeductions: boolean;
  id: string;
  openStatus: boolean;
  pageType: string;
  TaxableIncome: {
    Section80C: string | null;
    Section80CCD: string | null;
    Section80D: string | null;
    HealthInsuranceforSelfSpouse: boolean;
    HealthInsuranceforSelfSpouseFamily: string | number;
    HealthInsuranceforSelfSpouseParents: string | number;
    Healthinsuranceforparents: boolean;
    HealthinsuranceforparentsFamilyStepOne: string | null;
    HealthinsuranceforparentsParentsStepOne: string | null;
    HealthinsuranceforparentsFamilyStepTwo: MyTypeAlias;
    HealthinsuranceforparentsParentsStepTwo: MyTypeAlias;
    Section80TTASection80TTB: string | null;
    [key: string]: string | boolean | any;
  }
  errors: ErrorObject;
  isLoaded: boolean;
  section80g: [];
  section80gga: [];
  section80ggc: [];


  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DeductionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deductionDropdown: string = ""
  deductionPostAPi: string = ""
  deductionDetailsGet: string = ""
  deductionDetailsDelete: string = ""
  donationDetailsGet: string = ""
  donationDetailsDelete: string = ""
  donationSectionDetailsGet: string = ""
  GdonationDetailsGet:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      dynamicDeductions: [],
      dropdownlist: {},
      opendeductions: false,
      pageType: "",
      id: "",
      openStatus: false,
      TaxableIncome: {
        Section80C: null,
        Section80CCD: null,
        Section80D: "choose",
        HealthInsuranceforSelfSpouse: false,
        HealthInsuranceforSelfSpouseFamily: "",
        HealthInsuranceforSelfSpouseParents: "",
        Healthinsuranceforparents: false,
        HealthinsuranceforparentsFamilyStepOne: null,
        HealthinsuranceforparentsParentsStepOne: null,
        HealthinsuranceforparentsFamilyStepTwo: "",
        HealthinsuranceforparentsParentsStepTwo: "",
        Section80TTASection80TTB: null
      },
      errors: {},
      isLoaded: false,
      section80g:[],
      section80ggc: [],
      section80gga: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.deductionDropdown === responseData) {
      this.handlesaveDropdowns(successMessage);
    }
    if (this.deductionDetailsGet === responseData) {
      this.handleDeductionsGet(successMessage);
    }
    if (this.deductionPostAPi === responseData) {
      this.handlePostSucess(successMessage)
    }
    if (this.donationDetailsDelete === responseData) {
      this.donationsGet()
      this.donationsSectionGet()
      this.donationsGetList()
      this.setState({ isLoaded: false })
    }
    if (this.donationSectionDetailsGet === responseData) {
      this.handleSection(successMessage)
    }
    if (this.GdonationDetailsGet === responseData) {
      this.handleGSection(successMessage)
    }
    if (this.donationDetailsGet === responseData) {
      this.handleDonationDetails(successMessage)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.employerDetails()
    this.deductionGet()
    this.donationsGet()
    this.donationsSectionGet()
    this.donationsGetList()
    // Customizable Area End
  }


  // Customizable Area Start
  handleSection = (apiResponse: []) => {
    if (apiResponse) {
      this.setState({ section80ggc: apiResponse,isLoaded:false })
    }
  }

  handleGSection = (apiResponse: []) => {
    if (apiResponse) {
      this.setState({ section80g: apiResponse,isLoaded:false })
    }
  }

  handleDonationDetails = (apiResponse:[]) => {
        if (apiResponse) {
      this.setState({ section80gga: apiResponse,isLoaded:false })
    }
  }
  handleonChangeTextDataForDeductions = (event: { target: { name: string; value: MyTypeAlias; }; }) => {
    const { name, value } = event.target
    if (name === "Section80D") {
      this.setState((prevState) => ({
        TaxableIncome: {
          ...prevState.TaxableIncome,
          [name]: value as string,
        },
      }));
    } else {
      const parsedValue = Number(value);
      if ((!isNaN(parsedValue)&& parsedValue >= 0) || value === '') {
        this.setState((prevState) => ({
          TaxableIncome: {
            ...prevState.TaxableIncome,
            [name]: value === '' ? '' : parsedValue,
          },
        }));
      }
    }
  }
  handleonChangSnFDeductions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; 
    if(/[^0-9\b]/.test(value)) {
      return;
    } else {
      this.setState((prevState:any) => {
        const newTaxableIncome = {
          ...prevState.TaxableIncome,
          [name]: value,
        };
    
        const { HealthInsuranceforSelfSpouseFamily, HealthInsuranceforSelfSpouseParents } = newTaxableIncome;
        const totalHealthInsurance = Number(HealthInsuranceforSelfSpouseFamily) + Number(HealthInsuranceforSelfSpouseParents);        
        if (totalHealthInsurance > 5000) {
          return prevState;
        }
      this.setState((prevState:any) => ({TaxableIncome: {...prevState.TaxableIncome, [name]: value}}))
    })
    }
  };
  handleCheckedValues = (event: { target: { name: string, checked: boolean }; }) => {
    const { name, checked } = event.target
    if (name == "Healthinsuranceforparents" && !checked) {
      this.state.errors["HealthinsuranceforparentsFamilyStepOne"] = false
      this.state.errors["HealthinsuranceforparentsParentsStepOne"] = false
      this.state.errors["HealthinsuranceforparentsFamilyStepTwo"] = false
      this.state.errors["HealthinsuranceforparentsParentsStepTwo"] = false
      this.setState((prev) => ({
        ...prev,
        TaxableIncome: {
          ...prev.TaxableIncome,
          HealthinsuranceforparentsFamilyStepOne: "",
          HealthinsuranceforparentsParentsStepOne: "",
          HealthinsuranceforparentsFamilyStepTwo: "",
          HealthinsuranceforparentsParentsStepTwo: ""
        }

      }))
    }
    if (name == "HealthInsuranceforSelfSpouse" && !checked) {
      this.state.errors["HealthInsuranceforSelfSpouseFamily"] = false
      this.state.errors["HealthInsuranceforSelfSpouseParents"] = false
      this.setState((prev) => ({
        ...prev,
        TaxableIncome: {
          ...prev.TaxableIncome,
          HealthInsuranceforSelfSpouseFamily: "",
          HealthInsuranceforSelfSpouseParents: "",
        }

      }))
    }

    this.setState((prevState) => ({
      TaxableIncome: {
        ...prevState.TaxableIncome,
        [name]: checked
      }
    }))
  }
  validateAllDeductions = () => {
    const { TaxableIncome } = this.state;
    let isValidate = true;

    const setError = (field: string) => {
      isValidate = false;
      this.state.errors[field] = true;
    };

    const validateField = (value: string | number | any, minValue: number, maxValue: number, field: string) => {
      const numericValue = Number(value);

      if (isNaN(numericValue) || numericValue < minValue || numericValue > maxValue || value === "") {
        setError(field);
      }
    };

    validateField(TaxableIncome.Section80C, 0, 150000, "Section80C");
    validateField(TaxableIncome.Section80CCD, 0, 50000, "Section80CCD");
    validateField(TaxableIncome.Section80TTASection80TTB, 0, 50000, "Section80TTASection80TTB");


    if (TaxableIncome.HealthInsuranceforSelfSpouse) {
      if (TaxableIncome.HealthInsuranceforSelfSpouseFamily === "") {
        setError("HealthInsuranceforSelfSpouseFamily");
      }
      if (TaxableIncome.HealthInsuranceforSelfSpouseParents === "") {
        setError("HealthInsuranceforSelfSpouseParents");
      }
    }

    if (TaxableIncome.Healthinsuranceforparents) {
      const validateHealthInsuranceParents = (field: string) => {
        const value = TaxableIncome[field];
        if (value === null || value === "") {
          setError(field);
        }
      };

      validateHealthInsuranceParents("HealthinsuranceforparentsFamilyStepOne");
      validateHealthInsuranceParents("HealthinsuranceforparentsParentsStepOne");
      validateHealthInsuranceParents("HealthinsuranceforparentsFamilyStepTwo");
      validateHealthInsuranceParents("HealthinsuranceforparentsParentsStepTwo");
    }


    return isValidate;
  }

  handleSubmit = () => {
    let data = this.validateAllDeductions()
    if (data) {
      this.deductionsPost()
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }
  handleOnFocus = (fieldName: string) => {
    this.setState((prevState:{errors:{}}) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: "",
      },
    }));
  };


  handlePostSucess = (apiResponse: {}) => {
    if (apiResponse) {
      this.setState({ openStatus: true })
    } else {
      this.setState({ openStatus: false })

    }
  }
  handlesaveDropdowns = (apiResponse: {}) => {
    if (apiResponse) {
      this.setState({ dropdownlist: apiResponse })
    }
  }
  handleDeductionsGet = (apiResponse: Deduction) => {
    console.log(apiResponse,"apiResponseapiResponse")
    if (apiResponse && apiResponse.data) {
      this.setState({ isLoaded: false })
      const { section80c, section80ccd, section80tta_or_section80ttb, Section80d, Sections } = apiResponse.data.attributes
      this.setState({
        id: apiResponse.data.id,
        dynamicDeductions: Sections,
        TaxableIncome: {
          Section80C: section80c,
          Section80CCD: section80ccd,
          Section80D: Section80d.data.attributes.parent_age_group,
          HealthInsuranceforSelfSpouse: Section80d.data.attributes.spouse_details.data.attributes.spouce_insurance,
          HealthInsuranceforSelfSpouseFamily: Section80d.data.attributes.spouse_details.data.attributes.self_and_family ?? "",
          HealthInsuranceforSelfSpouseParents: Section80d.data.attributes.spouse_details.data.attributes.parent ?? "",
          Healthinsuranceforparents: Section80d.data.attributes.parent_details.data[0].attributes.parent_insurance,
          HealthinsuranceforparentsFamilyStepOne: Section80d.data.attributes.parent_details.data[0].attributes.self_and_family,
          HealthinsuranceforparentsParentsStepOne: Section80d.data.attributes.parent_details.data[0].attributes.parent,
          HealthinsuranceforparentsFamilyStepTwo: Section80d.data.attributes.parent_details.data[1].attributes.self_and_family,
          HealthinsuranceforparentsParentsStepTwo: Section80d.data.attributes.parent_details.data[1].attributes.parent,
          Section80TTASection80TTB: section80tta_or_section80ttb
        },
      })
    } else {
      this.setState({ isLoaded: false })

    }
  }
  handleopendeductions = (type: string) => {
    this.setState({ opendeductions: !this.state.opendeductions, pageType: type })
    this.donationsGet();
    this.donationsGetList()
    this.donationsSectionGet()
  }
  handleAddDynamicDeductions = () => {
    const datalenght = this.state.dynamicDeductions.length
    const emptyobj = { section_type: "choose", name: "Disability Serious", value: "", id: datalenght }
    this.setState((prevState) => ({
      ...prevState,
      dynamicDeductions: [...this.state.dynamicDeductions, emptyobj]
    }))

  }
  handleOnchangeForDynamicSelectedValues = (event: { target: { value: string } }, id: number | string, select: boolean, isActiveSelect: boolean) => {
    const inputData = [...this.state.dynamicDeductions]
    const find = inputData.find((item: { section_type: string, name: string, value: string, id: string | number }) => item.id == id)

    if (find !== undefined) {
      if (select) {
        find["section_type"] = event.target.value;
      } else if (isActiveSelect) {
        find["name"] = event.target.value;
      }
      else {
        if(/[^0-9\b]/.test(event.target.value)) {
          return
        }
        find["value"] = event.target.value;
      }
      this.setState({ dynamicDeductions: inputData })
    }


  }

  deleteDynamicContent = (id: string | number) => {
    const filteredData = this.state.dynamicDeductions.filter((item) => item.id !== id)
    this.setState((prevState) => ({
      ...prevState,
      dynamicDeductions: filteredData
    }))
  }

  employerDetails = async () => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deductionDropdown = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dropdownsDeductions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  deductionsPost = async () => {
    const {
      Section80C,
      Section80CCD,
      Section80D,
      HealthInsuranceforSelfSpouse,
      HealthInsuranceforSelfSpouseFamily,
      HealthInsuranceforSelfSpouseParents,
      Healthinsuranceforparents,
      HealthinsuranceforparentsFamilyStepOne,
      HealthinsuranceforparentsParentsStepOne,
      HealthinsuranceforparentsFamilyStepTwo,
      HealthinsuranceforparentsParentsStepTwo,
      Section80TTASection80TTB,
    } = this.state.TaxableIncome;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const httpBody: {
      deduction: {
        id?: string | number
        section80c: string | null;
        section80ccd: string | null;
        section80tta_or_section80ttb: string | null;
        section80d_attributes: {
          parent_age_group: string | null;
          health_insurance_for_spouse_attributes: {
            spouce_insurance: boolean;
            self_and_family: string | number;
            parent: string | number;
          };
          health_insurance_for_parents_attributes: {
            parent_insurance: boolean;
            self_and_family: MyTypeAlias;
            parent: MyTypeAlias;
          }[];
        };
        sections:{
          section_type: string;
          name: string;
          value: string;
          id: string | number;
          [key: string]: string | number;
        }[];
      };
    } = {
      deduction: {
        section80c: Section80C,
        section80ccd: Section80CCD,
        section80tta_or_section80ttb: Section80TTASection80TTB,
        section80d_attributes: {
          parent_age_group: Section80D,
          health_insurance_for_spouse_attributes: {
            spouce_insurance: HealthInsuranceforSelfSpouse,
            self_and_family: HealthInsuranceforSelfSpouseFamily,
            parent: HealthInsuranceforSelfSpouseParents
          },
          health_insurance_for_parents_attributes: [
            {
              parent_insurance: Healthinsuranceforparents,
              self_and_family: HealthinsuranceforparentsFamilyStepOne,
              parent: HealthinsuranceforparentsParentsStepOne
            },
            {
              parent_insurance: Healthinsuranceforparents,
              self_and_family: HealthinsuranceforparentsFamilyStepTwo,
              parent: HealthinsuranceforparentsParentsStepTwo
            }
          ],

        },
        sections: [
          ...this.state.dynamicDeductions
        ]
      }
    };
    if (this.state.id !== "") {
      httpBody.deduction.id = this.state.id;
    }
    const getpasswordfrom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deductionPostAPi = getpasswordfrom.messageId;

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deductionEndPoint
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod

    );
    runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }

  deductionGet = async () => {
    this.setState({ isLoaded: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deductionDetailsGet = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deductionGetCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleTextMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.clickaway) {
      return;
    }
    this.setState({ openStatus: false })
  };

  donationsGet = async () => {
    this.setState({ isLoaded: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.donationDetailsGet = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listEndPoints + "section80gga"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  donationsGetList = async () => {
    this.setState({ isLoaded: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GdonationDetailsGet = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listEndPoints + "section80g"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  donationsSectionGet = async () => {
    this.setState({ isLoaded: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.donationSectionDetailsGet = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listEndPoints + "section80ggc"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  donationsDelete = async (id: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.donationDetailsDelete = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.donationEndPoint + "/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  // Customizable Area End
}
