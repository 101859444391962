import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    Theme,
    // Customizable Area Start
    TextField,
    FormControl,
    Select,
    MenuItem
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End

import BusinessProfessionalIncomeController, {
    Props,
    configJSON,
} from "./BusinessProfessionalIncomeController.web";
import Toster from "../../../components/src/Toster";

export class IncomeForBusiness extends BusinessProfessionalIncomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    BusinessrendercommonComponetSelectInputDonations = (stylesprop: string, name: string, value: string, onChange:any, error: boolean | undefined) => {
        const { classes } = this.props;

        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error} id="incomeselect">
                    <Select
                        placeholder="select"
                        variant="outlined"
                        onFocus={this.handleFocusBusiness}
                        name={name}
                        value={value}
                        data-test-id={name}
                        fullWidth
                        onChange={onChange}
                        error={error}
                    >
                        <MenuItem style={{ color: '#ABABB0', display: "none" }} value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>

                        <MenuItem value={10} id="incomeselectvalue1">Ten</MenuItem>
                        <MenuItem value={20} id="incomeselectvalue2">Twenty</MenuItem>
                        <MenuItem value={30} id="incomeselectvalue3">Thirty</MenuItem> 
                    </Select>

                    <Box>
                        {<Typography variant="caption" className={classes.incomehelpertext}>
                            {configJSON.incomehelpertext}
                        </Typography>}
                    </Box>

                </FormControl >
            </Box>

        )
    }
    BusinessrenderStandardInputforBusinessIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <TextField
                className={classes.incomeselectdropDown}
                variant="outlined"
                disabled={name == "presumptiveIncome"}
                error={error}
                placeholder={configJSON.emptyPlaceholder}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                onFocus={this.handleFocusBusiness}
                data-test-id={name}
                helperText={error && "requried field"}
                FormHelperTextProps={{
                    style: { margin: '-12px 0 0 0' },
                }}

            />
        )
    }
    BusinessrenderCostumTextfiledforBusinessIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <Box className={classes.incomeamountWrapper}>
                <Box className={classes.incomeruppeslogoWrapper}>
                    <Typography className={classes.incomerupeessstyles}>₹</Typography>
                </Box>
                <TextField
                    className={classes.incometextfiledWrapper}
                    error={error}
                    disabled={name == "presumptiveIncome"}
                    variant="outlined"
                    placeholder={configJSON.passplaceHolder}
                    name={name}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleFocusBusiness}
                    data-test-id={name}
                    helperText={error && "requried field"}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}

                />
            </Box>
        )
    }

    BusinessrenderCommonTextFieldforBusinessIncome = (IncomecommonAttributes:
        {
            IncomeamountActive: boolean,
            IncomeamountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            Incomeselectactive:boolean,
            IncometextInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
            heading: string,
        }) => {
        const { classes } = this.props;
        const { IncomeamountValues, IncometextInputValues } = IncomecommonAttributes
        let rendertxt;
        if (IncomecommonAttributes.IncomeamountActive) {
            rendertxt = this.BusinessrenderCostumTextfiledforBusinessIncome(IncomeamountValues.name, IncomeamountValues.value, IncomeamountValues.type, IncomeamountValues.onChange, IncomeamountValues.error)
        }
        else if (IncomecommonAttributes.Incomeselectactive){
            rendertxt = this.BusinessrendercommonComponetSelectInputDonations(classes.incomeselectdropDown ,IncomeamountValues.name, IncomeamountValues.value, IncomeamountValues.onChange, IncomeamountValues.error)

        }
        else {
            rendertxt = this.BusinessrenderStandardInputforBusinessIncome(IncometextInputValues.name, IncometextInputValues.value, IncometextInputValues.type, IncometextInputValues.onChange, IncometextInputValues.error)
        }

        return (
            <Box>
                <Box className={classes.incometextAlignment}>
                    <Box className={classes.incomeboxwidth}>
                        <Typography className={classes.incomelabelheading}>{IncomecommonAttributes.heading}</Typography>
                    </Box>
                    <Box>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }
    BusinessrendercommonComponetDeductionsforBusinessIncome = () => {
        const { classes } = this.props;
        const { nameofBusiness,
            grossRecipt,
            netProfit,
            presumptiveIncome, error, grossReceiptscheques, grossReceiptscash} = this.state;
            const { selectedValue } = this.state;


        return (
            <>
                <Box className={classes.incomeheadingwrapeer}>
                    <Typography className={classes.incomesubheadingdonations}>{configJSON.natureofBussiness}</Typography>
                </Box>
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.nameofBusiness,
                    IncomeamountActive: false,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    IncometextInputValues: { name: "nameofBusiness", value: nameofBusiness, type: "string", onChange: this.handleOnchangeIncome, error: error["nameofBusiness"] },
                })}
                <Box>
                    <Box className={classes.incometextAlignment} style={{ alignItems: "flex-start" }}>
                        <Box className={classes.incomeboxwidth}>
                            <Typography className={classes.incomelabelheading} >{"Business code"}</Typography>
                        </Box>
                        <Box className={classes.incomestylesprop}>
                            <FormControl variant="outlined" error={error} style={{ width: '500px' }}>
                                <Autocomplete
                                data-test-id="income_autocomplete"
                                    options={this.state.codes}
                                    getOptionLabel={(option: any) => option.code}
                                    value={this.state.codes.find((item: any) => item.code === selectedValue) || null}
                                    onChange={this.handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                data-test-id="income_autocomplete_textfield"

                                            placeholder="Search or select business code"
                                            variant="outlined"
                                            error={false}
                                            className={classes.selectDropDown}
                                            InputProps={{
                                                ...params.InputProps,
                                                classes: {
                                                    notchedOutline: classes.incomecustomBorder,
                                                    input: classes.incomeAutocompleteinput
                                                },
                                            }}
                                        />
                                    )}
                                />
                                
                            </FormControl>
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.incomeheadingwrapeer} style={{paddingTop:"30px"}}>
                    <Typography className={classes.incomesubheadingdonations}>{configJSON.recieptsAndProfits}</Typography>
                </Box>
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.grossReceipts,
                    IncomeamountActive: true,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "grossRecipt", value: grossRecipt, type: "number", onChange: this.handleOnchangeIncome, error: error["grossRecipt"] },
                    IncometextInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.grossReceiptscheques,
                    IncomeamountActive: true,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "grossReceiptscheques", value: grossReceiptscheques, type: "number", onChange: this.handleOnchangeIncome, error: error["grossReceiptscheques"] },
                    IncometextInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.grossReceiptscash,
                    IncomeamountActive: true,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "grossReceiptscash", value: grossReceiptscash, type: "number", onChange: this.handleOnchangeIncome, error: error["grossReceiptscash"] },
                    IncometextInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.netProfits,
                    IncomeamountActive: true,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "netProfit", value: netProfit, type: "number", onChange: this.handleOnchangeIncome, error: error["netProfit"] },
                    IncometextInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.BusinessrenderCommonTextFieldforBusinessIncome({
                    heading: configJSON.PresumptiveIncome,
                    IncomeamountActive: true,
                    Incomeselectactive: false,
                    IncomeamountValues: { name: "presumptiveIncome", value: presumptiveIncome, type: "number", onChange: this.handleOnchangeIncome, error: error["presumptiveIncome"] },
                    IncometextInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}

            </>
        )
    }
 
    
    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
               <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
                <Container className={classes.incomemainWrapper} maxWidth={false}>

                    <Box className={classes.incomeconditionalheading}>
                    <span style={{cursor:"pointer"}} data-test-id="back_button" onClick={()=>this.handlenavigation()}><ArrowBackIosIcon fontSize="large" /></span><Typography className={classes.incomedonationdeading}>Professional Income (44AD)</Typography>
                    </Box>
                    <Box className={classes.incomebackgroundWrapper}>
                        {this.BusinessrendercommonComponetDeductionsforBusinessIncome()}
                    </Box>
                    <Box >
                        <Button className={classes.incomebuttonComponent} onClick={this.handleSubmitIncomeData} data-test-id="next" >Submit</Button>
                    </Box>
                </Container>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

export const styles = (theme: Theme) => ({
    incomeamountWrapper: {
        display: "flex"
    },
   
    incomeruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },

    incometextfiledWrapper: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },

    },
    incometextAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 8em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    incomemainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },

    incomeselectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    },
    incomecustomBorder: {
        border: '1px solid #C4C2C2', 
      },

      incomeAutocompleteinput: {
        padding: '8px 10px',
        height: '10px', 
        boxSizing: 'border-box',
      } as const,
    incomebackgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    incomeboxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },



    incomesubheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },


    incomelabelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    incomerupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },

    incomedonationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    incomebuttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },

    incomehelpertext: {
        display: "flex",
        aliginItems: "center",
        gap: "5px",
        marginTop: '0.9em',
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 350,
        fontFamily: "Nunito",

    },
    incomeconditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },

    incomeheadingwrapeer: {
        marginLeft: '2em'

    },

})
export default withStyles(styles)(IncomeForBusiness);
// Customizable Area End